import { SetterOrUpdater } from 'recoil';
import { FlattenMissingElements } from '../features/ConditionalRendering/hooks/interfaces';
import { FormData } from '../stores/atom/FormData';

interface AddMissingElementsToAppState {
	currentScreen: number;
	elementsNotInState:string[];
	missingItemsInStateWithDefaults: FlattenMissingElements;
	setFormData: SetterOrUpdater<FormData>;
}

export function addMissingElementsToAppState({
	missingItemsInStateWithDefaults, elementsNotInState, setFormData, currentScreen,
}:AddMissingElementsToAppState) {
	Object.keys(missingItemsInStateWithDefaults).forEach((key) => {
		if (!(elementsNotInState.includes(key))) return;
		const value = missingItemsInStateWithDefaults[key];
		setFormData((prevData) => ({
			...prevData,
			[currentScreen]: {
				...prevData[currentScreen],
				[key]: value,
			},
		}));
	});
}
