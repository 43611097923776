import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import DetectRelationChangesSelector from '../../../stores/selector/DetectRelationChangesSelector';
import Conditionals from '../stores/atom/Conditionals';
import RenderElementsAtom from '../../../stores/atom/RenderElements';
import FilteredData from '../../../stores/atom/FilteredData';
import FormHiddenFields from '../../../stores/atom/FormHiddenFields';
import { Relations, UseConditionalRender } from './interfaces';
import { findNameInRelation } from '../../../utils/findNameInRelation';

/**
 * @param name
 *
 * Comparion on each render if element should be displayed or not. If it's not displayed
 * then it should be filtered from submit
 */
function useConditionalRender({ name }: UseConditionalRender) {
	const [renderElement, setRenderElement] = useRecoilState(RenderElementsAtom);
	const [, setFilteredData] = useRecoilState(FilteredData);
	const hiddenFormFields = useRecoilValue(FormHiddenFields);
	const conditionals = useRecoilValue(Conditionals);
	const relations: Relations[] = useRecoilValue(DetectRelationChangesSelector);

	/**
	 * On Relation/Conditional Change this is the single point of truth for handling
	 * the render of each element. It creates a object of keys which are the unique names of a field
	 * and assignes a boolean if it should be displayed.
	 */
	useEffect(() => {
		hiddenFormFields?.forEach((field) => {
			const fieldName = field?.name;
			if (!fieldName || !(fieldName in conditionals)) return;

			const relation = findNameInRelation({ name: fieldName, relations });
			if (!relation) return;

			const { action } = Object.values(relation)[0];
			const shouldRender = action !== 'hide';
			setRenderElement((prev) => ({ ...prev, [fieldName]: shouldRender }));
		});

		if (!name) return;
		if (!(name in conditionals)) return setRenderElement((prev) => ({ ...prev, [name]: true }));
		const relation = findNameInRelation({ name, relations });
		if (!relation) return;
		const valuesOfRelation = Object.values(relation)[0];
		const {
			action, children,
		} = valuesOfRelation;
		if (action === 'hide') {
			/**
			 * If a element has children, all children should be hidden.
			 * If it hasn't, just the element
			 */
			return setRenderElement((prev) => {
				if (children) {
					const setChildrenToFalse = Object.keys(children)
						.reduce((prevItem, key) => ({ ...prevItem, [key]: false }), {});
					return ({ ...prev, ...setChildrenToFalse, [name]: false });
				}
				return ({ ...prev, [name]: false });
			});
		}

		return setRenderElement((prev) => ({ ...prev, [name]: true }));
	}, [relations, conditionals, hiddenFormFields]);

	/**
	 * on change of each renderElement we need to make sure,
	 * that all hidden fields are in synch with the filtered Data
	 */
	useEffect(() => {
		const shouldBeHidden = Object.keys(renderElement)
			.filter((elementKey) => !renderElement[elementKey]);
		setFilteredData(() => [...new Set([...shouldBeHidden])]);
	}, [renderElement]);

	return renderElement;
}
export default useConditionalRender;
