import { getRecoil } from 'recoil-nexus';
import FormMandant from 'stores/atom/FormMandant';
import {
	TariffCalculationError,
	TariffCalculationRequestData,
	TariffCalculationResponseData,
} from 'features/TariffComparison/interfaces/';
import { ContentType, HTTPHeader } from 'apiClient/restLayer/Constants';
import { fetchPostRequest } from '../restLayer';
import { Service } from './Constants';
import getFormGatewayUrl from './formGatewayUrl';

export default async function tariffCalculation(
	tariffIds: Array<string>,
	fieldsForCalculation: TariffCalculationRequestData,
) {
	const formMandant = getRecoil(FormMandant);
	const formGatewayUrl = getFormGatewayUrl();
	const tariffIdsString = tariffIds.join(',');
	const tariffCalculationUrl = `${formGatewayUrl}/${Service.tariffCalculation}/${formMandant}?${Service.tariffIds}=${tariffIdsString}`;
	const headers: HeadersInit = {
		[HTTPHeader.contentType]: ContentType.json,
		[HTTPHeader.accept]: ContentType.joseAndJson,
	};
	return fetchPostRequest(tariffCalculationUrl, fieldsForCalculation, { headers })
		.then((payload) => payload as TariffCalculationResponseData | TariffCalculationError);
}
