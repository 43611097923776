import { atom } from 'recoil';

export interface RenderElements {
	[name: string]: boolean;
}

const RenderElementsAtom = atom<RenderElements>({
	default: {},
	key: 'RenderElements',
});

export default RenderElementsAtom;
