import { Conditional } from '../features/ConditionalRendering/hooks/interfaces';

export function getDefaultValuesForAppState(
	elementsNotInState: string[],
	conditionals: Conditional,
) {
	return elementsNotInState.map((element) => Object.keys(conditionals).map((cond) => {
		const { children } = conditionals[cond];
		if (children && element in children) {
			return ({ [element]: children[element] });
		}
		return false;
	}).filter((isNotFalse) => isNotFalse)).flat(1);
}
