import React from 'react';
import { getRecoil } from 'recoil-nexus';
import '@oev-dxp/oev-components/dist/card';
import DynamicHtmlTag from 'utils/DynamicHtmlTag';
import { textContentMarkup } from 'utils';
import { WalletProps } from '../interfaces/WalletProps';
import WalletServiceResponse from '../stores/atom/WalletServiceResponse';

function Wallet({ wallet, sectionId }: Readonly<WalletProps>) {
	const walletServiceResponse = getRecoil(WalletServiceResponse);

	return (
		<oev-row-v1 col-width-large="8" key={sectionId}>
			<oev-column-v1 small-landscape-end="-3" small-landscape-start="3">
				<oev-card background-color="card-background-14" variant="horizontal">

					<oev-image
						alt="Wallet QR Code"
						class="no-ratio"
						defaultimage={walletServiceResponse.qrCodeURI}
						slot="media"
					/>

					<oev-headline slot="header-title" variant="headline-1">
						<DynamicHtmlTag slot="headline" tagName={wallet?.headline?.hLevel ?? 'h2'}>{wallet?.headline?.text}</DynamicHtmlTag>
					</oev-headline>
					<oev-headline slot="subtitle">
						<DynamicHtmlTag slot="sub-headline" tagName={wallet?.subheading?.hLevel ?? 'h4'}>{wallet?.subheading?.text}</DynamicHtmlTag>
					</oev-headline>

					<oev-text-content
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={textContentMarkup(wallet?.description ?? '')}
						slot="body-text"
					/>

					<oev-link
						buttons-position="left"
						buttons-width="half-width"
						color={wallet?.cta?.colorScheme}
						data-event-category="OEV-LINK"
						event-label={wallet?.cta?.dataEventLabel}
						href={walletServiceResponse.linkToPassPage}
						icon={wallet?.cta?.icon}
						icon-position={wallet?.cta?.iconPosition}
						slot="buttons"
						target={wallet?.cta?.target}
						title={wallet?.cta?.titleText}
						variant={wallet?.cta?.layout}
					>
						{wallet?.cta?.labelText}
					</oev-link>
				</oev-card>
			</oev-column-v1>
		</oev-row-v1>
	);
}

export default Wallet;
