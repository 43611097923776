import { atom } from 'recoil';

export interface EngagementList {
	engagedItems: string[];
	previousStep: {
		id: string;
	};
}

// needed because navigation doesn't have stepper items
const EngagementListAtom = atom<EngagementList>({
	default: {
		engagedItems: [],
		previousStep: {
			id: '',
		},
	},
	key: 'EngagementList',
});

export default EngagementListAtom;
