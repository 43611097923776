import React, { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import FormOverride from 'stores/atom/FormOverride';
import FormHiddenFields from '../../stores/atom/FormHiddenFields';
import ScreenContext from '../../stores/selector/ScreenContextSelector';
import { componentMap, SupportedComponent } from '../../components/constants/SupportedComponents';
import useFormData from '../../hooks/formular/useFormData';
import usePreserveData from '../../hooks/formular/usePreserveData';
import registerConditions from '../../features/ConditionalRendering/hooks/registerConditions';
import useConditionalRender from '../../features/ConditionalRendering/hooks/useConditionalRender';
import useFormMetaData from '../../hooks/formular/useFormMetaData';
import {
	calculateNumberOfColumns,
	checkDefaultValue,
	getLabel,
	getOptions,
} from './Utils';
import usePlaceholderResolver from '../../hooks/usePlaceholderResolver';
import getSessionStorage from '../../utils/formRedirect/getSessionStorage';
import { ComponentFactoryProps } from './Interfaces';
import { BaseComponentData } from '../../interfaces';

function ComponentFactory({
	type, data, headline, sectionId,
}: ComponentFactoryProps) {
	const { currentScreen: screen } = useRecoilValue(ScreenContext);
	const [hiddenFormFields, setHiddenFormFields] = useRecoilState(FormHiddenFields);
	const formOverride = getRecoil(FormOverride) ?? {};
	const name = (data?.name ?? data?.uuid) ?? '';
	const show = useConditionalRender({ name });
	const placeholderResolver = usePlaceholderResolver();
	const oevUserSessionData = getSessionStorage();
	const userFormData = oevUserSessionData?.formData;

	// LS-193 & LS-2110 default value priority from highest to lowest:
	// 1. formOverride - data passed via bootstrap parameter
	// 2. userFormData - data from sessionStorage
	// 3. component config default - data from form config
	const defaultValue = formOverride[name]
		|| userFormData?.[name]
		|| checkDefaultValue({ data, type });
	const resolvedDefaultValue = placeholderResolver(defaultValue ?? null, show);

	useEffect(() => {
		setHiddenFormFields((fields) => {
			// Return early if neither formOverride nor userFormData has relevant fields
			const hasRelevantFields = fields?.some(
				(field) => field.name in formOverride || (userFormData && field.name in userFormData),
			);
			if (!hasRelevantFields) return fields;

			// Proceed with updating the fields
			return fields?.map((field) => {
				if (field.name in formOverride) {
					return { ...field, defaultValue: placeholderResolver(formOverride[field.name] ?? '') };
				}
				if (userFormData && field.name in userFormData) {
					return { ...field, defaultValue: placeholderResolver(userFormData[field.name] ?? '') };
				}
				return field;
			});
		});
	}, []);

	const { desktopWidth, condition, ...rest } = data;
	const { tariffRelevant: isTariffRelevant } = rest as BaseComponentData;
	registerConditions({ condition: condition || [], hiddenFormFields, name });
	// on Init, for double check if value exist, new attribute data?.name
	const onChange = useFormData(
		name ?? type,
		// Some OEV Components use 'defaultValue' (oev-text-input-v1)
		// others use 'checked' (oev-checkbox)
		{
			screen,
			value: resolvedDefaultValue,
		},
		name,
	);

	const getPreservedData = usePreserveData(name);
	const preservedData = getPreservedData();
	const props = {
		...rest,
		default: preservedData || resolvedDefaultValue,
		defaultValue: preservedData || resolvedDefaultValue,
		id: name,
		onChange,
		type,
		uuid: data?.uuid,
	};
	const { id } = props;

	useFormMetaData({
		headline,
		isTariffRelevant,
		key: id,
		label: getLabel(props),
		options: getOptions(props),
		sectionId,
		type: type || '',
	});

	const numberOfColumns = calculateNumberOfColumns(desktopWidth);
	const Control = componentMap.get(type as SupportedComponent) ?? null;
	if (!(show?.[name])) return null;

	return Control
		&& (
			<oev-column-v1 small-landscape-width={numberOfColumns}>
				<Control key={name} {...props} />
			</oev-column-v1>
		);
}

export default ComponentFactory;
