import { Component } from './Component';

export interface Components {
	actionButton?: Component;
	checkbox?: Component;
	datepicker?: Component;
	navigationButton?: Component;
	progressTabs? : Component;
	selectInput?: Component;
	textInput?: Component;
	textOutput?: Component;
	upload?: Component;
}

export function getPresentComponent(wrapper: Components) {
	return (
		wrapper?.actionButton
		|| wrapper?.datepicker
		|| wrapper?.navigationButton
		|| wrapper?.checkbox
		|| wrapper?.selectInput
		|| wrapper?.textInput
		|| wrapper?.textOutput
		|| wrapper?.upload
		|| wrapper?.progressTabs
	);
}
