import React from 'react';
import { useRecoilValue } from 'recoil';
import SubmitData from '../../stores/selector/SubmitDataSelector';
import { FormDataValue } from '../../stores/atom/FormData';

function LastPage() {
	const submitData: FormDataValue = useRecoilValue(SubmitData);
	return (
		<div className="resultScreen" data-testid="summary_test">
			{Object.keys(submitData).map((key) => (
				<p key={key}>
					{[key]}
					{' '}
					:
					{' '}
					{submitData[key]}
				</p>
			))}

		</div>
	);
}

export default LastPage;
