import tariffCalculation from '../../../apiClient/serviceLayer/tariffCalculation';
import { TariffCalculationRequestData } from '../interfaces';

export async function getTariffCalculation(
	tariffIds: Array<string>,
	fieldsForCalculation: TariffCalculationRequestData,
) {
	const errorMessage = 'Error while fetching tariff calculation';
	try {
		const res = await tariffCalculation(tariffIds, fieldsForCalculation);
		return res;
	} catch (err) {
		// eslint-disable-next-line no-alert
		alert(errorMessage);
		throw new Error(errorMessage, { cause: err });
	}
}
