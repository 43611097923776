import React from 'react';
import AgencyLocationSearchCard from '../interfaces/AgencyLocationSearchCard';

interface AgencyCardListProps {
	cards: AgencyLocationSearchCard[];
	showMoreText?: string; // Currently not provided by the form configuration
}

function AgencyCardList({
	cards, showMoreText,
}: Readonly<AgencyCardListProps>) {
	return (
		<oev-agency-card-list
			cards={JSON.stringify(cards)}
			maximumreachedtext="Alle zum Suchbegriff passenden Berater geladen"
			showMoreText={showMoreText || 'Mehr laden'}
			showcards={cards?.length < 3 ? cards?.length : 3}
		/>
	);
}

export default AgencyCardList;
