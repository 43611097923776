export enum AgencyEvent {
	agencySelect = '@Agency/select',
	agencySwitch = '@Agency/switch',
	search = '@Agency/search',
	searchInputChange = '@SearchBarInput:change',
}

export enum ApiErrorState {
	area = 'NOT_IN_BUSINESS_AREA',
	noResult = 'NO_RESULTS_FOUND',
	noError = 'NO_ERROR',
}

export enum AgencyDataSource {
	default = 'default',
	search = 'search',
}

export enum AgencyType {
	AGENTUR,
	SPARKASSE,
	VOLKSRAIFFEISENBANK,
	BAYERISCHE_BEAMTENKRANKENKASSE,
	GESCHAEFTSSTELLE,
	OLB,
	AGENTUR_SELBSTSTAENDIG,
	LBS,
}
