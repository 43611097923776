import { atom } from 'recoil';
import getSessionStorage from 'utils/formRedirect/getSessionStorage';
import { SelectedTariffAtom } from '../../interfaces';

const oevUserSessionData = getSessionStorage();
const selectedTariff = oevUserSessionData?.selectedTariff;

const SelectedTariff = atom<SelectedTariffAtom>({
	default: selectedTariff ?? {},
	key: 'SelectedTariff',
});

export default SelectedTariff;
