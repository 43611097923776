import { defaultRequestSettings, HTTPMethod } from 'apiClient/restLayer/Constants';
import { Service } from 'apiClient/serviceLayer/Constants';
import getFormGatewayUrl from 'apiClient/serviceLayer/formGatewayUrl';
import { FileMetaData } from '../Interfaces';

// TODO: separate business-logic from request-logic (/src/apiClient/serviceLayer/postUploadFile.ts)
const deleteFile = async (
	newFile: FileMetaData,
) => {
	const fileUploadUrl = `${getFormGatewayUrl()}/${Service.formUpload}/${newFile.storageId}`;

	const requestSettings = { ...defaultRequestSettings };
	delete requestSettings.headers;

	try {
		const response = await fetch(fileUploadUrl, {
			...requestSettings,
			method: HTTPMethod.delete,
		});
		if (!response.ok) return false;
		return true;
	} catch (error: any) {
		return false;
	}
};

export default deleteFile;
