import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import CurrentScreenDataSelector from '../../stores/selector/CurrentScreenDataSelector';

export default function usePreserveData(name: string) {
	const { all: allData } = useRecoilValue(CurrentScreenDataSelector);

	return useCallback(() => {
		const preservedData = allData[name];
		if (!preservedData) return;
		return preservedData;
	}, [allData]);
}
