import React from 'react';
import { GroupSeparatorStyle, SeparatorStyle } from './Summary.style';

interface SeparatorProps {
	current: number;
	isGroup?: boolean;
	summaryLength: number;
}

function Separator({ current, summaryLength, isGroup }: Readonly<SeparatorProps>) {
	return (
		<span>
			{current !== summaryLength && (
				<hr
					data-testid="separator"
					style={isGroup ? GroupSeparatorStyle : SeparatorStyle}
				/>
			)}
		</span>
	);
}
export default Separator;
