import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/checkbox';
import '@oev-dxp/oev-components/dist/tooltip';
import { textContentMarkup } from '../../utils';
import { setLabelText } from '../../utils/setLabelText';
import { Event } from './Constants';
import { TooltipMargin } from './Checkbox.style';
import { ChangeEvent, CheckboxProps } from './Interfaces';

function Checkbox({
	checked,
	isCheckbox = true,
	label = '',
	labelAlignment = 'right',
	onChange,
	validation = {
		mandatory: {
			choice: '',
			errorMessage: '',
		},
		regex: {
			errorMessage: '',
			expression: '',
		},
	},
	...props
}: Readonly<CheckboxProps>) {
	const isMandatory = validation?.mandatory.choice === 'mandatory';
	const errorMessage = validation?.mandatory.errorMessage ?? '';
	const checkboxRef = useRef<EventTarget | null>(null);

	useEffect(() => {
		const handleChange = (event: ChangeEvent) => onChange({
			value: event.detail.checked.toString(),
		});

		checkboxRef.current?.addEventListener(Event.change, handleChange);

		return () => {
			checkboxRef.current?.removeEventListener(Event.change, handleChange);
		};
	}, []);

	const tooltip = props.tooltip
		? (
			<span style={TooltipMargin as React.CSSProperties}>
				<oev-tooltip
					content={props.tooltip}
					show-close-icon=""
				/>
			</span>
		)
		: null;

	const setLabel = setLabelText(label, isMandatory, isCheckbox);

	return (
		<>
			<oev-checkbox
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={textContentMarkup(setLabel ?? '')}
				error={errorMessage}
				label-alignment={labelAlignment}
				ref={checkboxRef}
				type="oev-checkbox"
				{...(props.default === 'true' ? { checked: '' } : null)}
				{...(isMandatory ? { mandatory: '' } : null)}
				{...props}
			/>
			{tooltip}
		</>
	);
}

export default Checkbox;
