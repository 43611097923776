import { TariffComparisonButton } from 'features/TariffComparison/interfaces';
import { atom } from 'recoil';

export interface PageProductTariff {
	buttons?: Array<TariffComparisonButton>;
	id?: string;
	[otherOption: string]: unknown;
}

export interface PageProduct {
	id?: string;
	[otherOption: string]: unknown;
	tariffs?: Array<PageProductTariff>;
}

export interface PageConfigData {
	[otherOption: string]: unknown;
	product?: PageProduct;
}

const PageConfigAtom = atom<PageConfigData>({
	default: {},
	key: 'PageConfig',
});

export default PageConfigAtom;
