import React, { useEffect, useRef, useState } from 'react';
import '@oev-dxp/oev-components/dist/upload-v1';
import { useRecoilState, useRecoilValue } from 'recoil';
import FormConfigMandant from 'stores/atom/FormConfigMandant';
import { Action, Event } from './Constants';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import FormDataStore from '../../stores/atom/FormData';
import SessionId from '../../stores/atom/SessionId';
import FormId from '../../stores/atom/FormId';
import EngagementListAtom from '../../stores/atom/EngagementList';
import '@oev-dxp/oev-components/dist/loading-indicators-v1';
import {
	AbortControllerMap, FileMetaData, NewFileMetaData, UploadProps,
} from './Interfaces';
import { setLabelText } from '../../utils/setLabelText';
import postFile from './api/postFile';
import deleteFile from './api/deleteFile';

function Upload({
	allowedFileFormats = '',
	description = '',
	dragAndDrop = '',
	errorMessageFileFormat = 'Dateityp konnte nicht bestimmt werden',
	errorMessageFileSize = 'Maximale Dateigröße überschritten',
	label,
	loadingErrorText = 'Die Datei konnte nicht geladen werden',
	maxFileSize = '',
	multiple = 'multiple',
	name = '',
	onChange,
	successMessage = 'Fertig geladen',
	uploadButtonText = '',
	uuid,
	validation = {
		mandatory: {
			choice: 'notmandatory',
			errorMessage: 'Bitte eine Datei auswählen',
		},
		regex: {
			errorMessage: '',
			expression: '',
		},
	},
	...props
}: Readonly<UploadProps>) {
	const ref = useRef<any>(null);
	const isMandatory = validation?.mandatory?.choice === 'mandatory';
	const errorMessage = validation?.mandatory?.errorMessage;
	const [uploadedFiles, setUploadedFiles] = useState<FileMetaData[]>([]);
	const formData = useRecoilValue(FormDataStore);
	const currentScreen = useRecoilValue(ScreenProgress);
	const sessionId = useRecoilValue(SessionId);
	const formId = useRecoilValue(FormId);
	const configMandant = useRecoilValue(FormConfigMandant);
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);
	const [abortControllers, setAbortControllers] = useState<AbortControllerMap>({});

	const saveStorageId = (file: NewFileMetaData, res: any) => {
		if (!res.success) throw new Error('Fehler beim Übertragen');
		const { storageId } = res;
		const { deleteError, setDeleteError, setErrorMessage } = file.customDelete;
		const newFile: FileMetaData = {
			base64DownloadURL: file.base64DownloadURL,
			customDelete: {
				deleteError,
				setDeleteError,
				setErrorMessage,
			},
			name: file.name,
			removeFile: file.removeFile,
			size: file.size,
			storageId,
			type: file.type,
		};

		// TODO change naming
		setUploadedFiles((prevState) => {
			const newFiles = [...prevState, newFile];
			const uniqueFiles: FileMetaData[] = [];

			newFiles.forEach((_file) => {
				const fileString = JSON.stringify(_file);
				if (!uniqueFiles.some((uniqueFile) => JSON.stringify(uniqueFile) === fileString)) {
					uniqueFiles.push(_file);
				}
			});

			return uniqueFiles;
		});

		// delete AbortController after successful upload
		setAbortControllers((prevState: AbortControllerMap) => {
			const { [file.name]: deletedController, ...tmpState } = prevState;
			return tmpState;
		});
	};

	const fileExists = (file: { name: string; }) => {
		// eslint-disable-next-line max-len
		if (uploadedFiles.find((existing: { name: string; }) => existing.name === file.name)) return true;
		// file might not be uploaded yet but already in the process
		return (file.name in abortControllers);
	};

	const uploadFile = (files: NewFileMetaData[]) => {
		Array.from(files).forEach((file: NewFileMetaData) => {
			if (fileExists(file)) return;
			const identifier = {
				configMandant,
				formId,
				sessionId,
				uuid,
			};
			postFile(file, identifier, saveStorageId, setAbortControllers);
		});
	};

	function cancelUpload(fileName: string) {
		const controller = abortControllers[fileName];
		if (controller) {
			controller.abort(); // cancel the fetch request
			delete abortControllers[fileName]; // remove the controller from the object
		}
	}

	const removeUploadedFile = async (fileToRemove: FileMetaData) => {
		const successful = await deleteFile(fileToRemove);
		const { setDeleteError, setErrorMessage } = fileToRemove.customDelete;
		if (!successful) {
			setDeleteError(fileToRemove.name, true);
			setErrorMessage(fileToRemove.name, 'Fehler beim Löschen');
			return;
		}
		fileToRemove?.removeFile(fileToRemove.name);
		setUploadedFiles((currentFiles) => currentFiles
			.filter((file) => file.name !== fileToRemove.name));
	};

	const removeFile = async (removedFile: string) => {
		cancelUpload(removedFile);
		const fileToRemove = uploadedFiles.find((file: { name: string; }) => file.name === removedFile);
		if (fileToRemove?.storageId) {
			await removeUploadedFile(fileToRemove);
			return;
		}

		// remove file that was not uploaded e.g. due to type error
		if (!fileToRemove) {
			const tempFile = { name: removedFile, removeFile: ref.current?.removeFile };
			tempFile.removeFile(tempFile.name);
		}
	};

	/* When a file upload fails, two events (add and then remove) are dispatched.
	To handle this, we need to first check if the file being deleted has been uploaded previously.
	If it has, then the removal should be added to the engagementList.
	However, if the removal is due to an immediate dispatch of the remove event,
	it shouldn't trigger another addition to the engagementList. */
	function handleEngagement(value: { mode: Action; removed: string; }) {
		const tempFile = { name: value.removed };
		if (value.mode === Action.remove && !fileExists(tempFile)) return;
		setEngagementList({
			...engagementList,
			engagedItems: [...engagementList.engagedItems, name],
		});
	}

	const UploadEvent = (ev: CustomEvent) => {
		handleEngagement(ev.detail.value);
		if (ev.detail.value.mode === Action.remove) {
			removeFile(ev.detail.value.removed);
			return;
		}
		uploadFile(ev.detail.value.file);
	};

	useEffect(() => {
		// use formData to load existing files
		if (formData[currentScreen]?.[name]) {
			const filesAsJson = JSON.parse(formData[currentScreen][name]) as Array<FileMetaData>;
			const existingFiles: FileMetaData[] = filesAsJson.map((file:FileMetaData) => ({
				...file,
				customDelete: {
					deleteError: false,
					setDeleteError: ref.current?.setDeleteError,
					setErrorMessage: ref.current?.setErrorMessage,
				},
				removeFile: ref.current?.removeFile,
			}));
			setUploadedFiles(existingFiles);
		}
	}, []);

	useEffect(() => {
		ref.current?.addEventListener(Event.upload, UploadEvent);

		return () => {
			ref.current?.removeEventListener(Event.upload, UploadEvent);
		};
	}, [uploadedFiles, engagementList, abortControllers]);

	useEffect(() => {
		if (!uploadedFiles) return;
		onChange({
			value: JSON.stringify(uploadedFiles),
		});
	}, [uploadedFiles]);

	return (
		<oev-upload-v1
			accepted-file-types={allowedFileFormats}
			button-text={uploadButtonText}
			custom-change-event-name={Event.upload}
			default={uploadedFiles.length}
			description={description}
			drag-and-drop-description={dragAndDrop}
			error-text-file-size={errorMessageFileSize}
			error-text-file-type={errorMessageFileFormat}
			error-text-loading={loadingErrorText}
			error-text-mandatory={errorMessage}
			error-text-max-file-size-exceeded={errorMessageFileSize}
			error-text-multiple-files-present="Nur eine Datei erlaubt"
			error-text-uploading="Der Dateiupload ist noch nicht beendet"
			existing-files={uploadedFiles.length === 0 ? null : `${JSON.stringify(uploadedFiles)}`}
			label={setLabelText(label, isMandatory)}
			max-file-size-in-mb={maxFileSize}
			name={name}
			ref={ref}
			success-message={successMessage}
			{...(isMandatory ? { mandatory: '' } : null)}
			{...(multiple ? { multiple: '' } : null)}
			{...(Object.keys(abortControllers).length !== 0 ? { 'is-loading': '' } : null)} // is-loading
			{...props}
		/>
	);
}

export default Upload;
