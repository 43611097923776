import { selector } from 'recoil';
import Conditionals from '../../features/ConditionalRendering/stores/atom/Conditionals';
import FormHiddenFields from '../atom/FormHiddenFields';
import CurrentScreenDataSelector from './CurrentScreenDataSelector';
import { ConditionalMetaData, Relations } from '../../features/ConditionalRendering/hooks/interfaces';
import { Comparator } from '../../components/constants';

// edge case - ticket SVDXP-2268, if is empty string, return null to match condition.
function handleEmptyValue(currentValue: string) {
	return currentValue?.trim() === '' ? null : currentValue;
}

function currentAction({ when, then, condition }: ConditionalMetaData, currentValue: string) {
	if (when === handleEmptyValue(currentValue) && condition === Comparator.equals) return then;
	if (when !== handleEmptyValue(currentValue) && condition === Comparator.equalsNot) return then;
	return null;
}

/**
 *
 * Selector which handles conditions depending on the current value of the Element
 *
 * @return returns an array of Relations between the referenced element and key.
 *
 */
const DetectRelationChangesSelector = selector({
	get: ({ get }) => {
		const { all: allData } = get(CurrentScreenDataSelector);
		const allConditionals = get(Conditionals);
		const hiddenFormFields = get(FormHiddenFields);

		// Merge allData with hiddenFormFields
		const allDataWithHiddenFields = { ...allData };
		hiddenFormFields?.forEach((field) => {
			allDataWithHiddenFields[field?.name] = field.defaultValue ?? '';
		});

		const relations: Relations[] = Object.values(allConditionals)
			.map((data) => ({
				[data.relationTo]: {
					currentValue: allDataWithHiddenFields[data.relationTo],
					...data,
					action: currentAction(data, allDataWithHiddenFields[data.relationTo]),
				},
			}));
		return relations;
	},
	key: 'DetectRelationChangesSelector',
});

export default DetectRelationChangesSelector;
