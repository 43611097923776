import { TrackingEvent } from '../../../components/constants';
import { TrackingData } from '../../../utils/tracking/getTrackingObject';
import { getInvalidFields } from '../../../utils';
import getInvalidFieldsWithError from '../../../utils/tracking/getInvalidFieldsWithError';
import dataLayerHandler from '../../../utils/tracking/dataLayerHandler';

export const shouldCalculate = (
	isTariffComparisonManuallyCalculated: boolean,
	filteredValues: Record<string, any>,
	trackingData: TrackingData,
) => {
	if (!Object.keys(filteredValues)?.length) throw new Error('No tariff relevant fields in state');

	const hasInvalidFields = getInvalidFields(filteredValues)?.length > 0;
	if (hasInvalidFields) {
		// if a manual calculation is triggered with invalid fields
		// call dataLayerHandler with the event profilerCalculateError
		// but proceed with validation to show the error messages for the user
		if (isTariffComparisonManuallyCalculated) {
			const invalidFields = getInvalidFieldsWithError(filteredValues);
			dataLayerHandler({
				event: TrackingEvent.profilerCalculateError,
				invalidFields,
				trackingData,
			});
			return true;
		}
		// if the calculation has not been triggered manually
		// and inputs within mandatory fields are missing,
		// the form should not be validated
		return false;
	}
	return true;
};
