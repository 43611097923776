import { selector } from 'recoil';
import FormConfig from '../atom/FormConfig';
import ScreenProgress from '../atom/ScreenProgress';

export default selector({
	get: ({ get }) => {
		const config = get(FormConfig);
		const progress = get(ScreenProgress);
		return config?.screens[progress];
	},
	key: 'ActiveScreen',
});
