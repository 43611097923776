import { atom } from 'recoil';
import { FormSequence } from '../../interfaces/FormSequence';

const FormConfig = atom<FormSequence>({
	default: {
		hiddenFields: [],
		id: '',
		name: '',
		progressTabs: false,
		screens: [],
	},
	key: 'FormConfig',
});

export default FormConfig;
