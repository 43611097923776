import { DesktopWidth } from '../../components/constants';
import { ComponentsData } from '../../interfaces';

export function calculateNumberOfColumns(
	desktopWidth: DesktopWidth = DesktopWidth.fullWidth,
	length: number = 12,
): number {
	const fraction = ({
		[DesktopWidth.fullWidth]: 1,
		[DesktopWidth.threeQuarters]: 3 / 4,
		[DesktopWidth.halfWidth]: 1 / 2,
		[DesktopWidth.oneThird]: 1 / 3,
		[DesktopWidth.quarterWidth]: 1 / 4,
		[DesktopWidth.twoThirds]: 2 / 3,
	})[desktopWidth] ?? 1;
	return fraction * length;
}

interface CheckDefaultValue {
	data: ComponentsData;
	type: string;
}

export function checkDefaultValue({ type, data }: CheckDefaultValue) {
	if (type === 'oev-checkbox') {
		if (data.checked === null) return 'false';
		return data.checked?.toString();
	}
	return data.default ?? data.defaultvalue;
}

export function getLabel(props: ComponentsData) {
	return (props as { label?: string; })?.label ?? '';
}

export function getOptions(props: ComponentsData) {
	return (props as {
		options: {
			label: string;
			value: string;
		}[];
	})?.options || [];
}
