import { WalletServiceResponseData } from 'features/Wallet';
import { atom } from 'recoil';

const WalletServiceResponse = atom<WalletServiceResponseData>({
	default: {
		identifier: '',
		linkToPassFile: '',
		linkToPassPage: '',
		qrCodeURI: '',
		uri: '',
	},
	key: 'WalletServiceResponse',
});

export default WalletServiceResponse;
