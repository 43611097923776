import React from 'react';
import { useRecoilValue } from 'recoil';
import Separator from './components/Separator';
import Headline from '../Headline/Headline';
import Content from './components/Content';
import useMediaQuery from '../../hooks/useMediaQuery';
import SummarySelector from '../../stores/selector/SummarySelector';
import {
	HeadLineDesktop, HeadLineMobile,
} from './components/Summary.style';
import { SummaryProps } from './Interfaces';
import usePlaceholderResolver from '../../hooks/usePlaceholderResolver';

function Summary({ headline, uuid }: Readonly<SummaryProps>) {
	const summary = useRecoilValue(SummarySelector);
	const isDesktopView = useMediaQuery('(min-width: 960px)');
	const placeholderResolver = usePlaceholderResolver();

	const resolvedHeadline = placeholderResolver(headline || null);
	return (
		<div>
			{Object.keys(summary).length > 0
				? (
					<oev-container-v1>
						<div style={isDesktopView ? HeadLineDesktop : HeadLineMobile}>
							<Headline headline={resolvedHeadline} variant="headline-2" />
						</div>
						{Object.keys(summary).map((screen, screenIndex) => (
							<div key={screen}>
								{Object.keys(summary[screenIndex]).map((section, sectionIndex) => (
									<Content
										isDesktopView={isDesktopView}
										key={`${uuid}-${section}`}
										sectionData={summary[screenIndex][section]}
										sectionIndex={sectionIndex}
										summaryScreenLength={Object.keys(summary[screenIndex]).length}
									/>
								))}
								{/* after each section a Separator should appear */}
								<Separator
									current={screenIndex + 1}
									summaryLength={Object.keys(summary).length}
								/>
							</div>
						))}
					</oev-container-v1>
				) : null}
		</div>
	);
}

export default Summary;
