import FormAgencyData from '../../features/Agency/interfaces/AgencyCardData';
import { fetchGetRequest } from '../restLayer';
import { Service } from './Constants';
import getFormGatewayUrl from './formGatewayUrl';

export default async function fetchAgencyData(agencyId: string, adMandant: string) {
	const formGatewayUrl = getFormGatewayUrl();
	const formConfigUrl = `${formGatewayUrl}/${Service.formMandant}/${adMandant}/${Service.agencyByNumber}/${agencyId}`;
	return fetchGetRequest(formConfigUrl).then((payload) => payload as FormAgencyData);
}
