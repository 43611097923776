// Refactor to an API module with async/await and handle response data types
// https://www.newline.co/@bespoyasov/how-to-use-fetch-with-typescript--a81ac257
import {
	defaultRequestSettings,
	ContentType,
	HTTPMethod,
	ErrorMessage,
} from './Constants';

function handleError(res: Response) {
	return res.ok ? res : Promise.reject(res.statusText);
}

function handleContentType(res: Response) {
	const contentType = res.headers.get('content-type') ?? '';
	const hasJsonPayload = contentType.includes(ContentType.json);
	const hasJoseAndJsonPayload = contentType.includes(ContentType.joseAndJson);

	return (hasJsonPayload || hasJoseAndJsonPayload)
		? res.json() : Promise.reject(ErrorMessage.unsupportedContentType);
}

function fetchRequest(url: string = '', settings: RequestInit = {}) {
	return fetch(url, {
		...defaultRequestSettings,
		...settings,
	}).then(handleError).then(handleContentType);
}

export function fetchGetRequest(url: string, settings: RequestInit = {}) {
	return fetchRequest(url, { method: HTTPMethod.get, ...settings });
}

export function fetchPostRequest(url: string, payload: any, settings: RequestInit = {}) {
	return fetchRequest(url, {
		body: JSON.stringify(payload),
		method: HTTPMethod.post,
		...settings,
	});
}
