import React from 'react';
import { EngagementList } from '../stores/atom/EngagementList';

export const updateEngagementListManually = (
	engagementList: EngagementList,
	name: string,
	setEngagementList: React.Dispatch<React.SetStateAction<EngagementList>>,
) => {
	const { engagedItems } = engagementList;
	const lastEngagedItems = engagedItems[engagedItems.length - 1];

	if (lastEngagedItems === name) return;

	setEngagementList({
		...engagementList,
		engagedItems: [...engagementList.engagedItems, name],
	});
};
