import { selector } from 'recoil';
import FormConfig from '../atom/FormConfig';
import ScreenProgress from '../atom/ScreenProgress';

export default selector({
	get: ({ get }) => {
		const config = get(FormConfig);
		const currentScreen = get(ScreenProgress);
		const finalScreen = config?.screens?.length ?? 0;
		const isDebuggingMode = process.env.NODE_ENV === 'development';

		return { currentScreen, finalScreen, isDebuggingMode };
	},
	key: 'ScreenContext',
});
