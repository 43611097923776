import { atom } from 'recoil';

export interface FormOverrideData {
	[otherOption: string]: string;
}

const FormOverrideAtom = atom<FormOverrideData>({
	default: {},
	key: 'FormOverride',
});

export default FormOverrideAtom;
