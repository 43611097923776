import { FormSequence } from '../../interfaces/FormSequence';
import { fetchGetRequest } from '../restLayer';
import { Service } from './Constants';
import getFormGatewayUrl from './formGatewayUrl';

export function fetchFormConfiguration(formId: string, configMandant: string) {
	const formGatewayUrl = getFormGatewayUrl();
	const formConfigUrl = `${formGatewayUrl}/${Service.formPath}/${formId}/${Service.formMandant}/${configMandant}/${Service.formConfig}`;
	return fetchGetRequest(formConfigUrl).then((payload) => payload as FormSequence);
}
