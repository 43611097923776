export const NavigationStyle = {
	display: 'flex',
	width: '100%',
};
export const ThreeItems = {
	display: 'flex',
	width: '33.3%',
};
export const TwoItems = {
	display: 'flex',
	width: '50%',
};

export const FlexNavigation = {
	alignItems: 'center',
	display: 'flex',
};
