import { validateForms } from '../validateForms';
import { FormDataValue } from '../../stores/atom/FormData';
import { scrollToElementWithOffset } from '../scrollToElementWithOffset';

export default function getInvalidFieldsWithError(
	formData: FormDataValue,
	shouldScrollToElement?: boolean,
	isTariffComparisonManuallyCalculated?: boolean,
) {
	const invalid = validateForms(formData, isTariffComparisonManuallyCalculated);
	if (!invalid || invalid?.length === 0) return;
	if (shouldScrollToElement) scrollToElementWithOffset(invalid[0]);

	return invalid?.map((key) => {
		const element = document.getElementById(key);
		const errorMessage = element?.getAttribute('errortext') ?? 'error';
		return {
			errorMessage,
			field: key,
		};
	});
}
