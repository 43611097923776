import { FunctionComponent } from 'react';
import ButtonFactory from '../../factories/ButtonFactory';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Dropdown from '../Dropdown';
import ProgressTabs from '../ProgressTabs';
import Radio from '../Radio';
import TextArea from '../TextArea';
import TextInput from '../TextInput';
import TextOutput from '../TextOutput';
import Tile from '../Tile';
import Upload from '../Upload';

export enum SupportedComponent {
	button = 'oev-button',
	checkbox = 'oev-checkbox',
	datepicker = 'oev-datepicker',
	radio = 'oev-input-radio-v1',
	dropdown = 'oev-text-dropdown-v1',
	textInput = 'oev-text-input-v1',
	textOutput = 'oev-text-content',
	textArea = 'oev-text-area-v1',
	tile = 'oev-text-kachel-v1',
	upload = 'oev-upload-v1',
	progressTabs = 'oev-progress-tabs',
}

export const componentMap = new Map<SupportedComponent, FunctionComponent>([
	[SupportedComponent.textInput, TextInput],
	[SupportedComponent.button, ButtonFactory],
	[SupportedComponent.checkbox, Checkbox],
	[SupportedComponent.datepicker, DatePicker],
	[SupportedComponent.dropdown, Dropdown],
	[SupportedComponent.radio, Radio],
	[SupportedComponent.textOutput, TextOutput],
	[SupportedComponent.tile, Tile],
	[SupportedComponent.textArea, TextArea],
	[SupportedComponent.upload, Upload],
	[SupportedComponent.progressTabs, ProgressTabs],
]);
