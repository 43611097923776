import React, { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import '@oev-dxp/oev-components/dist/progress-tabs';
import { Event } from './Constants';
import { ProgressTabsProps } from './Interfaces';

function ProgressTabs({
	keys, activeKey, ...props
}: Readonly<ProgressTabsProps>) {
	const ref = useRef<HTMLElement | null>(null);
	const setScreenProgress = useSetRecoilState(ScreenProgress);

	useEffect(() => {
		ref.current?.addEventListener(Event.change, (ev: CustomEvent) => {
			const newScreenProgress = (parseInt(ev.detail.payload.screen, 10));
			setScreenProgress(newScreenProgress);
		});
	}, []);

	return (
		<oev-progress-tabs
			ref={ref}
			{...props}
			activeKey={activeKey}
			data-testID="testIdProgressTabs"
			keys={keys}
		/>
	);
}
export default ProgressTabs;
