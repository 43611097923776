import React from 'react';
import AgencyCardList from './AgencyCardList';
import SearchBar from './SearchBar';
import { AgencySearch } from '../interfaces/AgencyProps';
import AgencyLocationSearchCard from '../interfaces/AgencyLocationSearchCard';

interface AgencyLocationSearchProps {
	agencyLocationSearchCards: Array<AgencyLocationSearchCard>;
	agencySearch?: AgencySearch;
	searchBarErrorMessage?: string;
	setSearchInput: Function;
}

function AgencyLocationSearch({
	agencyLocationSearchCards,
	agencySearch,
	searchBarErrorMessage,
	setSearchInput,
}: Readonly<AgencyLocationSearchProps>) {
	return (
		<>
			<SearchBar
				buttonText={agencySearch?.searchbuttontext ?? 'Suchen'}
				buttonTitle={agencySearch?.searchbuttontitle ?? 'Suchen'}
				description={agencySearch?.description}
				helpText={agencySearch?.helptext}
				placeholder={agencySearch?.placeholder}
				searchBarErrorMessage={searchBarErrorMessage}
				searchLabel={agencySearch?.agencysearchlabel}
				setSearchInput={setSearchInput}
				validation={agencySearch?.validation}
			/>
			{agencyLocationSearchCards.length
				? (
					<AgencyCardList
						cards={agencyLocationSearchCards}
						showMoreText="Mehr laden"
					/>
				) : null}
		</>
	);
}

export default AgencyLocationSearch;
