import { animateScroll } from 'react-scroll';

export function scrollToElementWithOffset(elementId: string, offset: number = 15) {
	const formElement = document.getElementById(elementId || '') as HTMLElement;
	if (!formElement) return;

	let headerOffset = 0;
	const headerElement = document.querySelector('oev-header-v1');
	if (headerElement?.hasAttribute('sticky')) {
		headerOffset = headerElement.getBoundingClientRect().height;
	}

	animateScroll.scrollTo(formElement.offsetTop - offset - headerOffset, {
		duration: 600,
		smooth: true,
	});
}
