import { selector } from 'recoil';
import FilteredData from '../atom/FilteredData';
import FormAtomData from '../atom/FormData';
import FormDataAtomProps from '../atom/FormDataMetaDataAtom';
import ScreenProgress from '../atom/ScreenProgress';
import { isUUID } from '../../utils';
import { isValidJSON } from './SubmitDataSelector';
import { FileMetaData } from '../../components/Upload';
import { SupportedComponentsTypes, Option } from '../../interfaces';
import SelectedAgencyAtom from '../atom/SelectedAgency';
import SummaryAtom from '../atom/SummaryAtom';

function normalizeUploadData(value: any) {
	if (isValidJSON(value)) {
		return (JSON.parse(value) as FileMetaData[])?.map((file) => file.name)?.join(', ');
	}
	if (!value?.length) return;
	return (value as FileMetaData[])?.map((file) => file.name)?.join(', ');
}
function normalizeCheckboxes(value: string) {
	if (value === 'true') return 'Ja';
	if (value === 'false') return 'Nein';
}

function normalizeData(value: string, type: SupportedComponentsTypes) {
	if (type === 'oev-upload-v1') return normalizeUploadData(value);
	if (type === 'oev-checkbox') return normalizeCheckboxes(value);
	return value;
}

export interface SectionData {
	headline: string;
	values: SectionValues;
}
export interface SectionValues {
	[key: string]: {
		label: string;
		options?: Option[];
		type: SupportedComponentsTypes;
		value: string;
	};
}

interface SummaryAppDataProps {
	[screen: number]: {
		[sectionId: string]: SectionData;
	};
}
function checkIfDataHasSectionValues(
	summaryData: SummaryAppDataProps,
	currentScreen: number,
	sectionId:string,
) {
	return {
		...((summaryData[currentScreen]?.[sectionId]?.values)
			|| {}),
	};
}

export default selector({
	get: ({ get }) => {
		const formData = get(FormAtomData);
		const filteredData = get(FilteredData);
		const formDataProps = get(FormDataAtomProps);
		const currentUserPosition = get(ScreenProgress);
		const agencyData = get(SelectedAgencyAtom);
		const unfilteredSummaryData = get(SummaryAtom);

		let summaryData = {} as SummaryAppDataProps;
		/**
		 * iterates over current formData and adds all the previous data the user has written down.
		 * If the user is on page 5, it collects all data from page 1-4
		 */
		Object.keys(formData).forEach((screen) => {
			const currentScreen = parseInt(screen, 10);
			if (currentScreen >= currentUserPosition) return;
			const currentFormScreen = formData[currentScreen];

			/**
			 * unfilteredFieldsOnScreen contains all keys for the current screen including Agency,
			 * uuids etc. even if not shown and summaryKeysWithAgency compares unfilteredFieldsOnScreen
			 * with visible fields (currentFormScreen)
			 */
			const unfilteredFieldsOnScreen = Object
				.values(unfilteredSummaryData)[currentScreen] as string[];
			const summaryKeysWithAgency = unfilteredFieldsOnScreen?.map((fieldKey: string) => {
				if (!currentFormScreen[fieldKey] && fieldKey !== 'Agency') return null;
				return fieldKey;
			});

			/**
			 * iterates over the current FormScreenData and checks if its either
			 * filtered, an UUID or has no formDataProps
			 */
			summaryKeysWithAgency.forEach((fieldKey: string) => {
				if (isUUID(fieldKey)) return;
				if (filteredData.includes(fieldKey)) return;
				if (!formDataProps[fieldKey] && fieldKey !== 'Agency') return;
				const {
					sectionId, headline, type, ...props
				} = formDataProps[fieldKey] || agencyData;
				const value = normalizeData(currentFormScreen[fieldKey], type) ?? '';

				/**
				 * builds the summaryData.
				 */
				summaryData = {
					...summaryData,
					[currentScreen]: {
						...summaryData[currentScreen],
						[sectionId]: {
							headline,
							values: {
								...checkIfDataHasSectionValues(summaryData, currentScreen, sectionId),
								[fieldKey]: {
									sectionId,
									type,
									...props,
									value,
								},
							},
						},
					},
				} as SummaryAppDataProps;
				return summaryData;
			});
		});
		return summaryData;
	},
	key: 'SummarySelector',
});
