import { getRecoil } from 'recoil-nexus';
import { ContentType, HTTPHeader } from 'apiClient/restLayer/Constants';
import SelectedTariff from 'features/TariffComparison/stores/atom/SelectedTariff';
import { fetchPostRequest } from '../restLayer';
import { Service } from './Constants';
import getFormGatewayUrl from './formGatewayUrl';

export interface FormApplicationPayload {
	'config-mandant': string;
	formID: string;
	mandant: string;
	produktnehmer: string;
	values: {
		[key: string]: any;
	};
}

export interface FormApplicationResultSuccess {
	result: string;
}

export interface FormApplicationResultSuccessParsed {
	applicationID: string;
	mandant: string;
	processSteps: Array<{
		operation: string;
		service: string;
		status: number;
		successful: boolean;
		text: string;
	}>;
	produktnehmer: string;
}

export interface FormApplicationResultError {
	errorMessage: string;
	errorType: string;
	reasonPhrase: string;
	status: number;
	success: string;
}

export function submitFormApplication(payload: FormApplicationPayload) {
	const baseUrl = getFormGatewayUrl();
	const formApplicationUrl = `${baseUrl}/${Service.formApplication}`;
	const selectedTariff = getRecoil(SelectedTariff);
	const headers: HeadersInit = {
		[HTTPHeader.contentType]: selectedTariff.signedTariffOffer
			? ContentType.joseAndJson
			: ContentType.json,
	};

	return fetchPostRequest(formApplicationUrl, payload, { headers }) as
		Promise<FormApplicationResultSuccess | FormApplicationResultError>;
}
