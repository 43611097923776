export interface PreviousStepError {
	errorMessage: string;
	field: string;
}

export function addExclamationMarksToErrorFields(
	engagedItems: string[],
	errors?: PreviousStepError[],
) {
	if (!errors) return engagedItems;
	return engagedItems.map((key) => (errors
		?.find((field) => field.field === key) ? `${key}!` : key));
}
