import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/search-bar';
import { SearchBarProps } from '../interfaces/SearchBar';
import { AgencyEvent } from '../Constants';
import { setLabelText } from '../../../utils/setLabelText';

interface SearchBarInputEvent extends Event {
	detail: {
		validity?: boolean;
		value: string;
	};
}

function SearchBar({
	buttonText,
	buttonTitle,
	description,
	helpText,
	placeholder,
	searchBarErrorMessage,
	searchLabel,
	setSearchInput,
	...props
}: Readonly<SearchBarProps>) {
	const ref = useRef<HTMLInputElement | null>();

	// Validation is not implemented yet and will probably be only handled in the agency component
	const { validation } = props;
	const isMandatory = validation?.mandatory?.choice === 'mandatory';
	const validationErrorMessage = validation?.mandatory?.errorMessage ?? '';

	const handleSearchEvent = (event: SearchBarInputEvent) => {
		const { value } = event.detail;
		setSearchInput(value);
	};

	useEffect(() => {
		ref?.current?.addEventListener(AgencyEvent.search, handleSearchEvent);

		return () => {
			ref?.current?.removeEventListener(AgencyEvent.search, handleSearchEvent);
		};
	}, []);

	return (
		<oev-search-bar
			{...props}
			buttonText={buttonText}
			buttonTitle={buttonTitle}
			description={description}
			error={searchBarErrorMessage}
			helpText={helpText}
			placeholder={placeholder}
			ref={ref}
			searchLabel={setLabelText(searchLabel, isMandatory)}
			validationErrorMessage={validationErrorMessage}
			{...(isMandatory ? { mandatory: '' } : null)}
		/>
	);
}

export default SearchBar;
