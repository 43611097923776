import { Alignment } from '../components/constants';

export const getAlignment = (alignment: Alignment) => {
	switch (alignment) {
		case Alignment.center:
			return 'center';
		case Alignment.left:
			return 'flex-start';
		case Alignment.right:
		default:
			return 'flex-end';
	}
};
