import React from 'react';
import NavigationButton from '../../components/NavigationButton';
import SubmitButton from '../../components/SubmitButton';
import { ButtonProps } from '../../components/Button/Interfaces';
import { ButtonAction } from '../../components/constants';
import CalculationButton from '../../components/CalculationButton';

export default function ButtonFactory({ action, ...rest }: Readonly<ButtonProps>) {
	switch (action) {
		case ButtonAction.calculation:
			return <CalculationButton {...rest} action={ButtonAction.calculation} />;
		case ButtonAction.next:
		case ButtonAction.back:
			return <NavigationButton action={action} {...rest} />;
		case ButtonAction.submit:
			return <SubmitButton {...rest} action={ButtonAction.submit} />;
		default:
			return null;
	}
}
