// ToDo: technical debt - this state should be in the Agency feature
import { atom } from 'recoil';
import getSessionStorage from 'utils/formRedirect/getSessionStorage';

export interface SelectedAgency {
	Agenturnummer: string;
	key: string;
	label?: string;
	orderNumber?: number;
	screen: number;
	sectionId?: string;
	type?: string;
	value: {
		Agenturadresse: string;
		Agenturname: string;
	};
}

const oevUserSessionData = getSessionStorage();
const selectedAgency = oevUserSessionData?.selectedAgency;

const SelectedAgencyAtom = atom<SelectedAgency>({
	default: selectedAgency ?? {
		Agenturnummer: '',
		key: '',
		label: '',
		orderNumber: 0,
		screen: 0,
		sectionId: '',
		type: '',
		value: {
			Agenturadresse: '',
			Agenturname: '',
		},
	},
	key: 'SelectedAgency',
});

export default SelectedAgencyAtom;
