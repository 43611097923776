import { FormDataMetaData } from '../../../stores/atom/FormDataMetaDataAtom';
import { FormDataValue } from '../../../stores/atom/FormData';

export const filterTariffRelevantFormData = (
	currentScreen: number,
	formData: { [screen: number]: FormDataValue; },
	formMetaData: FormDataMetaData,
) => {
	const tariffRelevantFieldKeys = Object.keys(formMetaData)
		.filter((key) => formMetaData[key].isTariffRelevant);
	const currentScreenFormData = formData[currentScreen ?? 0] ?? {};
	const filteredValues = Object.fromEntries(
		Object.entries(currentScreenFormData)
			.filter(([key]) => tariffRelevantFieldKeys.includes(key)),
	);
	return filteredValues;
};
