import React, { useEffect } from 'react';

export default function ThemeGenerator({ theme }: Readonly<{ theme: string; }>) {
	useEffect(() => {
		if (!theme || theme === 'whitelabel') return;
		import(`@oev-dxp/oev-components/dist/theme-${theme}.js`);
		import(`@oev-dxp/oev-components/dist/theme-${theme}.css`);
	}, [theme]);

	return (<div data-theme={theme} />);
}
