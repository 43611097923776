import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/text-area-v1';
import { TextAreaEvent } from '../constants';
import { TextAreaProps } from './interfaces';
import { setLabelText } from '../../utils/setLabelText';

function TextArea({
	label,
	onChange,
	validation = {
		mandatory: {
			choice: '',
			errorMessage: '',
		},
		regex: {
			errorMessage: '',
			expression: '',
		},
	},
	...props
}: Readonly<TextAreaProps>) {
	const ref = useRef<HTMLElement | null>(null);
	const isMandatory = validation?.mandatory?.choice === 'mandatory';
	const errorText = validation?.mandatory?.errorMessage ?? '';
	const regexExpression = validation?.regex?.expression ?? null;
	const regexErrorMessage = validation?.regex?.errorMessage ?? null;
	const minRows = props?.multirow?.minrows ?? null;
	useEffect(() => {
		ref.current?.addEventListener(TextAreaEvent.click, (ev: CustomEvent) => onChange({
			value: ev.detail.value,
		}));
	}, []);

	return (
		<oev-text-area-v1
			custom-change-event-name={TextAreaEvent.click}
			data-testID="testIdTextInput"
			errorText={errorText}
			label={setLabelText(label, isMandatory)}
			ref={ref}
			{...isMandatory && { mandatory: '' }}
			{...minRows && { minrows: minRows }}
			{...props}
			{...regexExpression && { regex: regexExpression }}
			{...regexErrorMessage && { regexErrorText: regexErrorMessage }}
		/>
	);
}

export default TextArea;
