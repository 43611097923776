import React from 'react';
import { EngagementList } from '../stores/atom/EngagementList';
import { SupportedComponent } from '../components/constants/SupportedComponents';

// TODO check if its okay to change the name attribute given that its not used for agency
export const isAgency = (focusedComponent: HTMLElement): boolean => {
	const component = focusedComponent.tagName.toLowerCase();
	if (component !== 'oev-search-bar') return false;
	focusedComponent.setAttribute('name', 'Agency');
	return true;
};

export const isSupportedComponent = (focusedComponent: HTMLElement): boolean => {
	const component = focusedComponent.tagName.toLowerCase() as SupportedComponent;
	return Object.values(SupportedComponent).includes(component) || isAgency(focusedComponent);
};

export const isNavigationButton = (focusedComponent: HTMLElement): boolean => {
	const component = focusedComponent.tagName.toLowerCase() as SupportedComponent;
	return component === SupportedComponent.button;
};

export const isUpload = (focusedComponent: HTMLElement): boolean => {
	const component = focusedComponent.tagName.toLowerCase() as SupportedComponent;
	return component === SupportedComponent.upload;
};

export const updateEngagementList = (
	e: React.FocusEvent<HTMLDivElement>,
	setEngagementList: React.Dispatch<React.SetStateAction<EngagementList>>,
	engagementList: EngagementList,
) => {
	if (!isSupportedComponent(e.target as HTMLElement)) return;
	if (isNavigationButton(e.target as HTMLElement)) return;
	// the engagement with upload is set within the upload component
	if (isUpload(e.target as HTMLElement)) return;

	const focusedComponent = (e.target as HTMLElement).getAttribute('name');
	if (!focusedComponent) return;
	setEngagementList({
		...engagementList,
		engagedItems: [...engagementList.engagedItems, focusedComponent],
	});
};
