import React from 'react';
import '@oev-dxp/oev-components/dist/text-content';
import useConditionalRender from '../../features/ConditionalRendering/hooks/useConditionalRender';
import usePlaceholderResolver from '../../hooks/usePlaceholderResolver';
import { textContentMarkup } from '../../utils';
import { TextOutputProps } from './Interfaces';

function TextOutput({
	onChange, outputText, ...props
}: Readonly<TextOutputProps>) {
	const show = useConditionalRender({ name: props.id });
	const placeholderResolver = usePlaceholderResolver();
	const resolvedOutputText = placeholderResolver(outputText || null, show);
	return (
		<oev-text-content
			{...props}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={textContentMarkup(resolvedOutputText ?? '')}
		/>
	);
}
export default TextOutput;
