import React from 'react';
import AgencyLocationSearchCard from '../interfaces/AgencyLocationSearchCard';
import { createTooltip } from './createToolTip';
import { parseOpeningHours } from '../utils/util';
import { AgencyEvent } from '../Constants';
import '@oev-dxp/oev-components/dist/card-slider-v1';
import '@oev-dxp/oev-components/dist/divider';

interface AgencySliderProps {
	agencyDataNummer?: string | null;
	agencyswitchbuttontext?: string;
	agencyswitchbuttontitle?: string;
	cards?: Array<AgencyLocationSearchCard>;
	label?: string;
	tooltip?: string;
}

function AgencySlider({
	cards,
	label,
	tooltip,
	agencyswitchbuttontext,
	agencyswitchbuttontitle,
	agencyDataNummer,
}: Readonly<AgencySliderProps>) {
	return (
		<>
			<span style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}>{label}</span>
			{createTooltip(tooltip)}
			<oev-divider />
			<oev-card-slider-v1
				amount-cards="1"
				amount-mobile="1"
				box-shadow-width="0"
				enabledTextSelection=""
				stretch-cards=""
			>
				{cards?.map((branch, index) => (
					<oev-agency-card
						branchname={branch?.branchName}
						buttonEvent={AgencyEvent.agencySwitch}
						buttontext={agencyswitchbuttontext || 'Berater wechseln'}
						buttontitle={agencyswitchbuttontitle || 'Berater wechseln'}
						city={branch?.city}
						commentOpeningHours={branch?.commentOpeningHours}
						distance={branch?.distance}
						fallbackImage={branch?.fallbackImage}
						image={branch?.image}
						index={index}
						key={branch?.orderNumber ?? branch?.branchName}
						postalcode={branch?.postalCode}
						street={branch?.street}
						structuredopeninghours={parseOpeningHours(branch?.structuredOpeningHours)}
						{...(agencyDataNummer ? { hideActionButton: '' } : null)}
					/>
				))}
			</oev-card-slider-v1>
		</>
	);
}

export default AgencySlider;
