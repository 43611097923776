import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/text-input-v1';
import { TextInputEvent } from '../constants';
import { TextInputProps } from './Interfaces';
import { setLabelText } from '../../utils/setLabelText';

function TextInput({
	label,
	onChange,
	validation = {
		mandatory: {
			choice: '',
			errorMessage: '',
		},
		regex: {
			errorMessage: '',
			expression: '',
		},
	},
	...props
}: Readonly<TextInputProps>) {
	const ref = useRef<HTMLInputElement | null>();

	const isMandatory = validation?.mandatory?.choice === 'mandatory';
	const errorText = validation?.mandatory?.errorMessage ?? '';
	const regexExpression = validation?.regex?.expression ?? null;
	const regexErrorMessage = validation?.regex?.errorMessage ?? null;

	useEffect(() => {
		ref.current?.addEventListener(TextInputEvent.changeEvent, (
			ev: CustomEvent,
		) => onChange(
			{ value: ev.detail.value },
		));
	}, []);

	return (
		<oev-text-input-v1
			custom-change-event-name={TextInputEvent.changeEvent}
			data-testID="testIdTextInput"
			errorText={errorText}
			label={setLabelText(label, isMandatory)}
			mandatory={isMandatory}
			ref={ref}
			{...props}
			{...regexExpression && { regex: regexExpression }}
			{...regexErrorMessage && { regexErrorText: regexErrorMessage }}
		/>
	);
}

export default TextInput;
