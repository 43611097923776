import React from 'react';
import '@oev-dxp/oev-components/dist/button';
import { ButtonProps } from './Interfaces';

function Button({
	color = '',
	eventLabel = '',
	icon = '',
	iconJustification = '',
	iconPosition = '',
	labelText = '',
	layout = '',
	onClick,
	titleText = '',
	...props
}: Readonly<ButtonProps>) {
	return (
		<oev-button
			{...props}
			color={color}
			event-label={eventLabel}
			icon={icon}
			icon-justification={iconJustification}
			icon-position={iconPosition}
			onClick={onClick}
			title={titleText}
			variant={layout}
		>
			{labelText}
		</oev-button>
	);
}

export default Button;
