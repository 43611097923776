import React from 'react';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { LoadingSkeleton } from 'view/LoadingSkeleton';
import App from './App';

// OEV web components that aren't stand alone
import '@oev-dxp/oev-components/dist/form-label';
import '@oev-dxp/oev-components/dist/icon';

// Prevents scripts from failing when a custom element is already defined
customElements.defineclone = Object.assign(Object.create(
	Object.getPrototypeOf(customElements),
).define, customElements);
customElements.define = (name, element) => customElements.get(name)
|| customElements.defineclone(name, element);

// FIXME: This is a workaround to support custom elements from oev-components in tsx
declare global {
	namespace JSX {
		interface IntrinsicElements {
			[elemName: string]: any;
		}
	}
}
const rootHTML = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootHTML);

root.render(
	<React.StrictMode>
		<RecoilRoot>
			<RecoilNexus />
			<LoadingSkeleton />
			<App
				adMandant={rootHTML.hasAttribute('data-ad-mandant') ? rootHTML.getAttribute('data-ad-mandant') : null}
				agencyId={rootHTML.hasAttribute('data-agn') ? rootHTML.getAttribute('data-agn') : null}
				configMandant={rootHTML.hasAttribute('data-config-mandant') ? rootHTML.getAttribute('data-config-mandant') : null}
				dataContentRoot={rootHTML.hasAttribute('data-contentroot') ? rootHTML.getAttribute('data-contentroot') : null}
				dataRegion={rootHTML.hasAttribute('data-region') ? rootHTML.getAttribute('data-region') : null}
				formId={rootHTML.hasAttribute('data-formid') ? rootHTML.getAttribute('data-formid') : null}
				formOverride={rootHTML.hasAttribute('data-form-override') ? rootHTML.getAttribute('data-form-override') : null}
				mandant={rootHTML.hasAttribute('data-mandant') ? rootHTML.getAttribute('data-mandant') : null}
				pageConfig={rootHTML.hasAttribute('data-page-config') ? rootHTML.getAttribute('data-page-config') : null}
				productOwner={rootHTML.hasAttribute('data-produktnehmer') ? rootHTML.getAttribute('data-produktnehmer') : null}
				theme={rootHTML.hasAttribute('data-theme') ? rootHTML.getAttribute('data-theme') : null}
			/>
		</RecoilRoot>
	</React.StrictMode>,
);
