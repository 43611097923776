import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import Button from '../Button';
import { Alignment, ButtonAction, TrackingEvent } from '../constants';
import { ButtonProps } from '../Button/Interfaces';
import { FormDataValue } from '../../stores/atom/FormData';
import EngagementListAtom from '../../stores/atom/EngagementList';
import TrackingSelector from '../../stores/selector/TrackingSelector';
import CurrentScreenDataSelector from '../../stores/selector/CurrentScreenDataSelector';
import SelectedTariff from '../../features/TariffComparison/stores/atom/SelectedTariff';
import { getAlignment } from '../../utils/getAlignment';
import { scrollToElementWithOffset, validateForms } from '../../utils';
import getInvalidFieldsWithError from '../../utils/tracking/getInvalidFieldsWithError';
import dataLayerHandler from '../../utils/tracking/dataLayerHandler';

export const ActionTypes = {
	back: -1,
	forward: +1,
};

function validateNextStep(strideWidth: number, currentScreenData: FormDataValue) {
	if (strideWidth !== ActionTypes.forward) return true;
	// trigger fields which are mandatory
	// setRecoilEventtrigger auf true
	const invalidForm = validateForms(currentScreenData);
	// get the first Element out of array and jump towards it.
	// considering that more error messages are to be issued in the future.
	if (invalidForm === undefined) return;
	if (invalidForm.length === 0) return true;
	const invalidFormElement: string = invalidForm?.[0];
	scrollToElementWithOffset(invalidFormElement);
}

export function nextAction(action: string) {
	return ActionTypes[action as keyof typeof ActionTypes];
}

// TODO throw error during upload process
function NavigationButton({
	action = ButtonAction.next,
	alignment = Alignment.right,
	...rest
}: Readonly<ButtonProps>) {
	const [currentScreen, setCurrentScreen] = useRecoilState(ScreenProgress);
	const { current: currentScreenData } = useRecoilValue(CurrentScreenDataSelector);
	const track = useRecoilValue(TrackingSelector);
	const selectedTariff = useRecoilValue(SelectedTariff);
	const strideWidth = nextAction(action);
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);

	const navigateStep = () => {
		const nextScreen = strideWidth + currentScreen;
		if (nextScreen < 0) return;
		if (validateNextStep(strideWidth, currentScreenData)) {
			setEngagementList({
				...engagementList,
				previousStep: { id: track.screens[currentScreen].id },
			});
			setCurrentScreen(nextScreen);
		}

		// there should be no Event.formularError push when going backwards
		if (strideWidth < 0) return;
		const invalidFields = getInvalidFieldsWithError(currentScreenData);
		if (!invalidFields) return;
		// Event.formularError push
		dataLayerHandler({
			event: TrackingEvent.formularError,
			invalidFields,
			trackingData: track,
			...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
		});
	};
	return (
		<div style={{ display: 'flex', justifyContent: getAlignment(alignment) }}>
			<Button {...rest} action={ButtonAction.next} onClick={navigateStep} />
		</div>
	);
}

export default NavigationButton;
