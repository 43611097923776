import React from 'react';

export const LabelStyle = {
	font: 'var(--oev-font-label)',
	fontWeight: 'var(--oev-font-weight-bold)',
} as React.CSSProperties;

export const ValueStyle = {
	font: 'var(--oev-font-family-body)',
	fontWeight: 'var(--oev-font-weight-body)',
	margin: 0,
} as React.CSSProperties;

export const ColumnStyle = {
	display: 'flex',
	flexDirection: 'column',
	rowGap: 'var(--oev-ref-spacing-12)',
} as React.CSSProperties;
