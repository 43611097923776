export default function getFormGatewayUrl() {
	let formGateway = process.env.REACT_APP_FORM_GATEWAY;
	// CI Pipeline sets CI_FORM_GATEWAY env
	if (window.env?.CI_FORM_GATEWAY && window.env?.CI_FORM_GATEWAY !== '__CI_FORM_GATEWAY__') {
		formGateway = window.env.CI_FORM_GATEWAY;
	}
	// Use json-server to mock the form gateway to get the form config in development.
	// Otherwise use the actual form gateway.
	let baseUrl = process.env.NODE_ENV === 'development' ? process.env?.REACT_APP_API_URL : formGateway;
	// Fallback if neither is defined
	if (!baseUrl) baseUrl = 'http://localhost:4000';
	return baseUrl;
}
