import { selector } from 'recoil';
import { isUUID } from 'utils';
import { OevSessionStorage } from 'utils/formRedirect/getSessionStorage';
import FormDataAtom from '../atom/FormData';
import SelectedTariff from '../../features/TariffComparison/stores/atom/SelectedTariff';
import SelectedAgencyAtom from '../atom/SelectedAgency';
import FilteredData from '../atom/FilteredData';

const UserSessionDataSelector = selector({
	get: ({ get }) => {
		const formData = get(FormDataAtom);
		const selectedTariff = get(SelectedTariff);
		const selectedAgency = get(SelectedAgencyAtom);
		const filteredData = get(FilteredData);

		// flatten the object to an array with only one object and key:values
		const flattenFormData = Object.keys(formData).map((screen) => formData[parseInt(screen, 10)])
			.reduce((prev, cur) => ({
				...prev,
				...cur,
			}), {});

		// filter hidden fields && uuids
		const filteredFormData = Object.fromEntries(
			Object.entries(flattenFormData)
				.filter(([key]) => !isUUID(key) && !filteredData.includes(key)),
		);

		const userSessionData: OevSessionStorage = {
			formData: filteredFormData,
			selectedAgency,
			selectedTariff,
		};

		return userSessionData;
	},
	key: 'UserSessionDataSelector',
});

export default UserSessionDataSelector;
