import { Branch, StructuredOpeningHours } from '../interfaces/AgencyCardData';
import AgencyCard from '../interfaces/AgencyCard';
import AgencyLocationSearchCard from '../interfaces/AgencyLocationSearchCard';
import AgencyLocationSearch from '../interfaces/AgencyLocationSearchData';
import { AgencyType } from '../Constants';

function buildAgencyImageUrl(
	dataContentRoot: string,
	dataRegion: string,
	agn?: string | null | undefined,
	agencyType?: string | number | null,
): string {
	if (!dataContentRoot || !dataRegion) return '';
	let type = agencyType;
	if (typeof type === 'number') type = AgencyType[type];
	if (typeof type === 'string' && Object.values(AgencyType).includes(type as keyof typeof AgencyType)) {
		type = AgencyType[type as keyof typeof AgencyType];
	}

	let { origin } = window.location;
	if (origin === 'http://localhost:3000') origin = 'https://magnolia-public.dxp-formtroopers.lan';
	const baseUrl = `${origin}/dam/${dataContentRoot}/aussendienst/${dataRegion}`;

	switch (type) {
		case AgencyType.AGENTUR:
		case AgencyType.AGENTUR_SELBSTSTAENDIG:
			return `${baseUrl}/agentur_${agn}/agenturbild.jpg`;
		case AgencyType.SPARKASSE:
			return `${baseUrl}/sparkasse.jpg`;
		case AgencyType.VOLKSRAIFFEISENBANK:
			return `${baseUrl}/volksraiffeisenbank.jpg`;
		case AgencyType.BAYERISCHE_BEAMTENKRANKENKASSE:
			return `${baseUrl}/bayerische_beamtenkrankenkasse.jpg`;
		case AgencyType.GESCHAEFTSSTELLE:
			return `${baseUrl}/geschaeftsstelle.jpg`;
		case AgencyType.OLB:
			return `${baseUrl}/olb.jpg`;
		case AgencyType.LBS:
			return `${baseUrl}/lbs.jpg`;
		default:
			return `${baseUrl}/agentur.jpg`;
	}
}

export function mapLocationSearchDataToSearchCards(
	agencyLocationSearchData: AgencyLocationSearch,
	dataContentRoot: string,
	dataRegion: string,
	buttonText?: string,
	buttonTitle?: string,
): AgencyLocationSearchCard[] {
	if (!agencyLocationSearchData?.branches?.length) return [];
	const cards: Array<AgencyLocationSearchCard> = agencyLocationSearchData?.branches?.map(
		(branch) => ({
			agn: branch.agn,
			branchName: branch.branchName,
			buttonText: buttonText || 'Wählen',
			buttonTitle: buttonTitle || 'Wählen',
			city: branch.city,
			distance: Math.round((branch.distance || 0) * 10) / 10,
			fallbackImage: buildAgencyImageUrl(dataContentRoot, dataRegion, branch?.agn),
			image: buildAgencyImageUrl(dataContentRoot, dataRegion, branch?.agn, branch.type ?? 0),
			orderNumber: branch.orderNumber,
			postalCode: branch.postalCode,
			street: branch.street,
			structuredOpeningHours: JSON.parse(branch.structuredOpeningHours ?? '') as StructuredOpeningHours,
		}),
	);
	return cards;
}

export function mapAgencyDataBranchesToCards(
	branches: Array<Branch>,
	dataContentRoot: string,
	dataRegion: string,
	agn: string | null | undefined,
	type: string | number | null,
	buttonText?: string,
	buttonTitle?: string,
): AgencyCard[] {
	const agencyDataCards: Array<AgencyCard> = branches?.map(
		(branch) => {
			if (!branch.name) return {};
			return {
				branchName: branch?.name,
				buttonText: buttonText || 'Wählen',
				buttonTitle: buttonTitle || 'Wählen',
				city: branch?.ort,
				commentOpeningHours: branch?.kommentarOeffnungszeiten,
				fallbackImage: buildAgencyImageUrl(dataContentRoot, dataRegion, agn),
				image: buildAgencyImageUrl(dataContentRoot, dataRegion, agn, type),
				orderNumber: branch?.ordnung,
				postalCode: branch?.plz,
				street: branch?.strasseHausnr,
				structuredOpeningHours: branch?.strukturierteOeffnungszeiten,
			};
		},
	);
	return agencyDataCards;
}

export function parseOpeningHours(hours: StructuredOpeningHours | undefined) {
	if (!hours) return null;
	return JSON.stringify(hours);
}
