import React, { useState } from 'react';
import { Theme } from './Interfaces';
import ThemeGenerator from '../../themes/ThemeGenerator';

export function ThemeSwitcher(props: Readonly<Theme>) {
	let { theme } = props;
	if (!theme) theme = 'whitelabel';
	const availableThemes = ['hfk', 'llb', 'oesa', 'pkp', 'pnb', 'pr', 'proam', 'sv', 'ukv', 'vkb', 'whitelabel'];
	const [selectedTheme, setSelectedTheme] = useState(availableThemes.includes(theme) ? theme : 'whitelabel');

	const themeOnChangeHandler = (value: string) => {
		if (!value || !availableThemes.includes(value)) return;
		setSelectedTheme((prevState) => {
			if (prevState === 'whitelabel') return value;
			document.querySelector('head style:last-child')?.remove();
			return value;
		});
	};

	const betaBorderStyle: React.CSSProperties = {
		alignItems: 'baseline',
		border: '7px solid black',
		borderImage: 'repeating-linear-gradient( -55deg, #000, #000 20px, #ffb101 20px, #ffb101 40px) 10',
		display: 'flex',
		gap: '4px',
		width: 'fit-content',
	};

	const betaBatchStyle: React.CSSProperties = {
		backgroundColor: 'red',
		borderRadius: '5px',
		color: '#ffffff',
		fontSize: '10px',
		fontWeight: 'bold',
		margin: '5px',
		padding: '3px 6px',
	};

	return (
		<div
			className="ThemeSwitcher"
			data-testid="ThemeSwitcher-Test-ID"
			style={betaBorderStyle}
		>
			<ThemeGenerator theme={selectedTheme} />
			<span>Theme switcher</span>
			<select
				onChange={(e) => themeOnChangeHandler(e.target.value)}
				role="menu"
				value={selectedTheme}
			>
				{availableThemes.map((t) => (
					<option key={t} value={t}>
						{t}
					</option>
				))}
			</select>
			<span style={betaBatchStyle}>BETA</span>
		</div>
	);
}
