import React from 'react';

export interface DynamicHtmlTagProps {
	children: React.ReactNode;
	slot?: string;
	tagName: string;
}

function DynamicHtmlTag({ tagName, children, slot = '' }: DynamicHtmlTagProps) {
	return React.createElement(
		tagName,
		{
			...(slot ? { slot } : null),
			'data-tagname': tagName,
			'data-testid': `dynamic-html-tag-${tagName}`,
		},
		children,
	);
}

export default DynamicHtmlTag;
