// @flow
import React from 'react';
import { ActionTypes } from '../NavigationButton/NavigationButton';
import Button from '../Button/Interfaces';
import ButtonFactory from '../../factories/ButtonFactory';
import { ButtonAction } from '../constants';
import registerConditions from '../../features/ConditionalRendering/hooks/registerConditions';
import useConditionalRender from '../../features/ConditionalRendering/hooks/useConditionalRender';
import { NavigationStyle, ThreeItems, TwoItems } from './Navigation.style';
import { RenderElements } from '../../stores/atom/RenderElements';

type AlignmentTypes = 'alignStart' | 'alignCenter' | 'alignEnd';

const Alignments = {
	alignCenter: { justifyContent: 'center' },
	alignEnd: { justifyContent: 'end' },
	alignStart: { justifyContent: 'start' },
};

function twoButtonLayout(
	maxAmountButtons: number,
	currentPositionOfButton: number,
) {
	if (currentPositionOfButton === 1) return 'alignStart';
	return 'alignEnd';
}
function threeButtonLayout(
	maxAmountButtons: number,
	currentPositionOfButton: number,
	alignButton: AlignmentTypes,
) {
	if (currentPositionOfButton === 2) return 'alignCenter';
	if (currentPositionOfButton === 3) return 'alignEnd';
	return alignButton;
}
// only 'hard' coded restriction to have 'back' action on the left hand
function oneButtonLayout(
	maxAmountButtons: number,
	currentPositionOfButton: number,
	alignButton: AlignmentTypes,
	action: ButtonAction,
) {
	const actionType = ActionTypes[action as keyof typeof ActionTypes];
	if (actionType === ActionTypes.back) return 'alignStart';
	return 'alignEnd';
}

function layoutHandler(
	maxAmountButtons: number,
	currentPositionOfButton: number,
	alignButton: AlignmentTypes,
	action: ButtonAction,
) {
	if (maxAmountButtons === 1) {
		return oneButtonLayout(maxAmountButtons, currentPositionOfButton, alignButton, action);
	}
	if (maxAmountButtons === 2) {
		return twoButtonLayout(maxAmountButtons, currentPositionOfButton);
	}
	return threeButtonLayout(maxAmountButtons, currentPositionOfButton, alignButton);
}

function determineAmountRenderedButtons(
	buttonsToRender: (Button | null)[],
	show: RenderElements,
) {
	return buttonsToRender.filter((
		renderedButtons,
	) => renderedButtons?.data.uuid && show[renderedButtons.data.uuid]).length;
}

interface NavigationSectionButtonProps {
	button: Button;
	buttonsToRender: (Button | null)[];
	currentPositionOfButton: number;
}
export function NavigationsSectionButton({
	button, buttonsToRender, currentPositionOfButton,
}: Readonly<NavigationSectionButtonProps>) {
	const { action, condition, uuid } = button.data;
	const show = useConditionalRender({ name: uuid });
	registerConditions({ condition: condition ?? [], name: uuid! });
	const maxAmountButtons = determineAmountRenderedButtons(buttonsToRender, show);
	const alignButton: AlignmentTypes = layoutHandler(maxAmountButtons, currentPositionOfButton, 'alignStart', action);

	if (!uuid || !(show?.[uuid])) return null;

	return (
		<div style={{
			...NavigationStyle,
			...Alignments[alignButton],
			...maxAmountButtons === 2 && TwoItems,
			...maxAmountButtons === 3 && ThreeItems,
		}}
		>
			<ButtonFactory {...button.data} />
		</div>
	);
}
