import agencyLocationSearch from '../../../apiClient/serviceLayer/formAgencyLocationSearch';

export async function searchAgencyByLocation(mandant: string, searchInput: string) {
	try {
		const res = await agencyLocationSearch(mandant, searchInput);
		return res;
	} catch (err) {
		return null;
	}
}
