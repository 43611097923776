import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import FormDataAtomProps from '../../stores/atom/FormDataMetaDataAtom';
import { SupportedComponentsTypes, Option } from '../../interfaces';

interface UseFormMetaData {
	headline?: string | null;
	isTariffRelevant?: boolean;
	key: string;
	label: string;
	options?: Option[];
	sectionId: string;
	type: SupportedComponentsTypes;
}

export default function useFormMetaData({
	key, isTariffRelevant, type, label, options, headline, sectionId,
}: UseFormMetaData) {
	const [formDataProps, setFormDataProps] = useRecoilState(FormDataAtomProps);

	useEffect(() => {
		if (formDataProps[key]) return;
		setFormDataProps((prevState) => ({
			...prevState,
			[key]: {
				headline,
				isTariffRelevant,
				label,
				options,
				sectionId,
				type,
			},
		}));
	}, []);
}
