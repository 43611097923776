/* eslint-disable react/no-array-index-key */
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';
import FormConfig from 'stores/atom/FormConfig';
import 'react-loading-skeleton/dist/skeleton.css';
import './LoadingSkeleton.css';

const inputSkeleton = (
	itemsInRow: number = 2,
	height: number = 48,
	customColWidths: Array<Number> = [],
) => {
	const calcWidthForRowItems = Math.round(12 / itemsInRow);
	return (
		<oev-row-v1 col-width-small={calcWidthForRowItems} mb="0">
			{Array.from({ length: itemsInRow }).map((el, i) => (
				<oev-column-v1 key={i} small-width={customColWidths[i]}>
					<Skeleton height={18} width="30%" />
					<Skeleton height={height} style={{ marginTop: '12px' }} />
				</oev-column-v1>
			))}
		</oev-row-v1>
	);
};

const radioSkeleton = (itemsInRow: number = 2) => {
	const calcWidthForRowItems = Math.round(12 / itemsInRow / 3);
	return (
		<oev-row-v1 col-width-small={calcWidthForRowItems} mb="0">
			{Array.from({ length: itemsInRow }).map((el, i) => (
				<oev-column-v1 key={i}>
					<div style={{ alignItems: 'center', columnGap: '12px', display: 'flex' }}>
						<Skeleton circle inline height={24} width={24} />
						<Skeleton inline containerClassName="radioLabelSkeleton" height={18} />
					</div>
				</oev-column-v1>
			))}
		</oev-row-v1>
	);
};

const headlineSkeleton = () => (
	<oev-row-v1 col-width-small={12} mb="0">
		<oev-column-v1>
			<Skeleton height={48} width="40%" />
		</oev-column-v1>
	</oev-row-v1>
);

const buttonSkeleton = () => (
	<oev-row-v1 col-width-small={12} mb="3rem">
		<oev-column-v1>
			<Skeleton containerClassName="buttonSkeleton" height={48} width={240} />
		</oev-column-v1>
	</oev-row-v1>
);

export function LoadingSkeleton() {
	const formConfig = useRecoilValue(FormConfig);

	return (
		<div
			className="LoadingSkeleton"
			data-testid="LoadingSkeleton-Test-ID"
		>
			<oev-container-v1>
				{formConfig.screens.length === 0
					? (
						<SkeletonTheme
							baseColor="#EAEAEA"
							borderRadius="0.25rem"
							highlightColor="#F9F9F9"
						>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{radioSkeleton(2)}
								{inputSkeleton(2)}
								{inputSkeleton(3, 48, [6, 2, 4])}
								{headlineSkeleton()}
								{inputSkeleton(1, 154)}
								{inputSkeleton(2)}
								{buttonSkeleton()}
							</div>
						</SkeletonTheme>
					)
					: null}
			</oev-container-v1>
		</div>
	);
}
