export enum ContentType {
	json = 'application/json',
	joseAndJson = 'application/jose+json',
}

export enum HTTPMethod {
	get = 'GET',
	post = 'POST',
	delete = 'DELETE',
}

export enum HTTPHeader {
	accept = 'Accept',
	contentType = 'Content-Type',
}

export enum ErrorMessage {
	unsupportedContentType = 'Content Type is unsupported.',
}

export const defaultRequestSettings: RequestInit = {
	cache: 'no-cache',
	credentials: 'same-origin',
	headers: { [HTTPHeader.contentType]: ContentType.json },
	mode: 'cors',
};
