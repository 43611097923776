import React from 'react';
import '@oev-dxp/oev-components/dist/headline';
import { HeadlineProps } from './Interfaces';
import { NoPadding } from './Headline.style';

function Headline({ headline, subheadline, variant }: Readonly<HeadlineProps>) {
	return (
		<oev-row-v1>
			<oev-column-v1>
				<oev-headline variant={variant}>
					{headline ? <h2 slot="headline" style={{ ...NoPadding }}>{headline}</h2> : null}
					{subheadline ? <h3 slot="sub-headline" style={{ ...NoPadding }}>{subheadline}</h3> : null}
				</oev-headline>
			</oev-column-v1>
		</oev-row-v1>
	);
}

export default Headline;
