import { useRecoilValue } from 'recoil';
import CurrentScreenDataSelector from '../stores/selector/CurrentScreenDataSelector';
import { RenderElements } from '../stores/atom/RenderElements';
import FormHiddenFields from '../stores/atom/FormHiddenFields';
import { FormDataValue } from '../stores/atom/FormData';

function usePlaceholderResolver() {
	const { all: flattenState } = useRecoilValue(CurrentScreenDataSelector);
	const HiddenFormFields = useRecoilValue(FormHiddenFields);

	return (
		textWithPossiblePlaceholders: string | null,
		renderedElements?: RenderElements,
	) => {
		const flattenStateWithHiddenFields: FormDataValue = {
			...HiddenFormFields?.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.defaultValue ?? '' }), {}),
			...flattenState,
		};
		if (!textWithPossiblePlaceholders) return;

		const regex = /@\{formular\.(\w+)\}/g;
		return textWithPossiblePlaceholders.replace(regex, (
			match,
			placeholderKey,
		) => {
			if (renderedElements?.[placeholderKey] === false) return '';

			return flattenStateWithHiddenFields[placeholderKey] ?? '';
		});
	};
}

export default usePlaceholderResolver;
