import getTrackObject, { GetTrackObject } from './getTrackingObject';

function initDataLayer() {
	return window?.dataLayer || [];
}

export default function dataLayerHandler({
	agencySearch, event, invalidFields, tariffName, trackingData,
}: GetTrackObject) {
	if (!window.dataLayer) window.dataLayer = initDataLayer();
	window.dataLayer.push(getTrackObject({
		agencySearch,
		event,
		invalidFields,
		tariffName,
		trackingData,
	}));
}
