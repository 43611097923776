import { useEffect, useState } from 'react';
import fetchAgencyData from 'apiClient/serviceLayer/formAgencyData';
import AgencyCardData from '../../features/Agency/interfaces/AgencyCardData';

export default function useAgencyCardData(agencyId: string, adMandant: string) {
	if (!agencyId || !adMandant) return {};
	const [agencyData, setAgencyData] = useState<AgencyCardData>();
	const [error, setError] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		fetchAgencyData(agencyId, adMandant)
			.then(setAgencyData)
			.catch(setError)
			.finally(() => setIsLoading(false));
	}, [agencyId, adMandant]);

	return { agencyData, error, isLoading };
}
