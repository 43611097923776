import React from 'react';
import { ErrorViewProps } from './Interfaces';

export function ErrorView(props: Readonly<ErrorViewProps>) {
	const { text, codeExample } = props;

	return (
		<div className="Error" data-testid="error_view">
			<p>{text}</p>
			<br />
			{codeExample ? <code>{codeExample}</code> : null}
		</div>
	);
}
