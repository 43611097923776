import React, { Fragment } from 'react';
import { useRecoilValue } from 'recoil';
import '@oev-dxp/oev-components/dist/grid-v1';
// TODO remove this import after refactoring to lit component
// import '@oev-dxp/oev-components/dist/grid-v1.css';
import { Wallet } from 'features/Wallet';
import Headline from '../Headline/Headline';
import Row from '../Row';
import Agency from '../../features/Agency/components';
import TariffComparison from '../../features/TariffComparison/components';
import registerConditions from '../../features/ConditionalRendering/hooks/registerConditions';
import useConditionalRender from '../../features/ConditionalRendering/hooks/useConditionalRender';
import registerChildrenElementsInAppState
	from '../../features/ConditionalRendering/hooks/registerChildrenElementsInAppState';
import usePlaceholderResolver from '../../hooks/usePlaceholderResolver';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import { RowProps, SectionProps, Element } from '../../interfaces';

function Section({
	backgroundColor,
	condition: sectionCondition,
	elements,
	headline,
	uuid,
}: Readonly<SectionProps>) {
	const sectionConditions = sectionCondition?.filter((cond) => cond.data.then);
	const currentScreen = useRecoilValue(ScreenProgress);
	const rows = elements?.map((element) => element.row)?.filter((row) => row !== null) as RowProps[];
	registerConditions({ condition: sectionConditions || [], name: uuid, rows });
	const show = useConditionalRender({ name: uuid });
	const placeholderResolver = usePlaceholderResolver();
	registerChildrenElementsInAppState({ currentScreen });
	const resolvedHeadlines = placeholderResolver(headline || null);
	if (!(show?.[uuid])) return null;

	return (
		<oev-container-v1 key={uuid} {...backgroundColor && { 'background-color': backgroundColor }}>
			{headline ? (<Headline headline={resolvedHeadlines} key={headline ?? `${uuid}-headline`} variant="headline-1" />) : null}
			<div>
				{elements?.map(({
					row, agent, tariffComparison, wallet, ...props
				}: Element) => (
					<Fragment key={row?.uuid || agent?.uuid}>
						{row ? (
							<div key={row.uuid}>
								<Row
									components={row.components}
									condition={row.condition}
									headline={headline}
									sectionId={uuid}
									uuid={row.uuid}
									{...props}
								/>
							</div>
						) : null}
						{agent ? (
							<div key={agent.uuid}>
								<Agency sectionId={uuid} {...agent} />
							</div>
						) : null}
						{tariffComparison ? (
							<div key={tariffComparison.productName}>
								<TariffComparison
									key={tariffComparison.productName ?? `${uuid}-tariff-comparison`}
									sectionId={uuid}
									tariffComparison={tariffComparison}
								/>
							</div>
						) : null}
						{wallet ? (
							<div key={wallet.uuid}>
								<Wallet
									key={`${uuid}-${wallet.uuid}`}
									sectionId={uuid}
									wallet={wallet}
								/>
							</div>
						) : null}
					</Fragment>
				))}
			</div>
		</oev-container-v1>
	);
}

export default Section;
