import { RenderElements } from '../stores/atom/RenderElements';
import { FormDataValue } from '../stores/atom/FormData';
import { isUUID } from './isUUID';

export function filterMissingElementsOfAppState(
	renderElement: RenderElements,
	allData: FormDataValue,
) {
	return Object.keys(renderElement)
		.filter((key) => !(key in allData))
		.filter((key) => !isUUID(key));
}
