import { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getRecoil } from 'recoil-nexus';
import { navigateStep } from '../utils';
import dataLayerHandler from '../../../utils/tracking/dataLayerHandler';
import { scrollToElementWithOffset } from '../../../utils';
import formRedirect from '../../../utils/formRedirect/formRedirect';
import getInvalidFieldsWithError from '../../../utils/tracking/getInvalidFieldsWithError';
import { TariffComparisonData, TariffComparisonMapped } from '../interfaces';
import { TariffEvent } from '../components/Constants';
import { TrackingEvent } from '../../../components/constants';
import FormAtomData from '../../../stores/atom/FormData';
import FormDataMetaDataAtom from '../../../stores/atom/FormDataMetaDataAtom';
import ScreenProgress from '../../../stores/atom/ScreenProgress';
import SelectedTariff from '../stores/atom/SelectedTariff';
import SignedTariffList from '../stores/atom/SignedTariffList';
import TrackingSelector from '../../../stores/selector/TrackingSelector';
import UserSessionDataSelector from '../../../stores/selector/UserSessionDataSelector';

export const useSelectTariff = (
	mappedTariffData: TariffComparisonMapped | undefined,
	tariffComparison: TariffComparisonData,
) => {
	const formMetaData = getRecoil(FormDataMetaDataAtom);
	const formData = getRecoil(FormAtomData);
	const signedTariffList = getRecoil(SignedTariffList);
	const trackingData = useRecoilValue(TrackingSelector);
	const userSessionData = useRecoilValue(UserSessionDataSelector);
	const [currentScreen, setCurrentScreen] = useRecoilState(ScreenProgress);
	const [selectedTariff, setSelectedTariff] = useRecoilState(SelectedTariff);
	const [eventState, setEventState] = useState<CustomEvent | null>(null);
	const prevEventRef = useRef<CustomEvent | null>(null);

	const validate = () => {
		const currentScreenFormData = formData[currentScreen ?? 0] ?? {};
		return getInvalidFieldsWithError(currentScreenFormData, true);
	};

	const handleEventAction = (event: CustomEvent) => {
		switch (event?.detail?.action) {
			case 'selectAndContinue':
				navigateStep(currentScreen, setCurrentScreen);
				break;
			case 'selectAndRedirect':
				formRedirect(event?.detail?.url, userSessionData);
				break;
			default: break;
		}
	};

	const selectTariff = (event: CustomEvent) => {
		const tariff = {
			productName: tariffComparison.productName ?? '',
			signedTariffOffer: signedTariffList?.[event?.detail?.tariffId],
			tariffId: event?.detail?.tariffId,
			tariffName: event?.detail?.tariffName,
		};
		// Set the selected tariff state
		setSelectedTariff(tariff);
		// Store the event in the state
		setEventState(event);
	};

	useEffect(() => {
		if (!eventState || prevEventRef.current === eventState) return;

		// Execute after state update
		const invalidFields = validate();
		if (invalidFields) {
			dataLayerHandler({
				event: TrackingEvent.formularError,
				invalidFields,
				trackingData,
				...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
			});
		}

		if (selectedTariff && !selectedTariff?.signedTariffOffer) {
			// eslint-disable-next-line no-alert
			alert('Bitte starten Sie die Preisberechnung zuerst!');
			scrollToElementWithOffset('TariffComparison');
		}
		if (!invalidFields?.length && selectedTariff?.signedTariffOffer) handleEventAction(eventState);

		prevEventRef.current = eventState;
	}, [selectedTariff, eventState, userSessionData]);

	useEffect(() => {
		document.addEventListener(TariffEvent.select, selectTariff);

		return () => {
			document.removeEventListener(TariffEvent.select, selectTariff);
		};
	}, [tariffComparison, mappedTariffData, formData, formMetaData, userSessionData]);

	return { selectTariff };
};
