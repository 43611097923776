export enum TrackingEvent {
	formularError = 'formular_error',
	formularExit = 'formular_navigation',
	formularInitialPageView = 'formular_initial',
	formularStep = 'formular_step',
	formularSubmit = 'formular_submit',
	formularSearch = 'formular_agencysearch',
	profilerCalculate = 'profiler_calculate',
	profilerCalculateError = 'profiler_calculate_error',
}

export enum FormType {
	offer = 'angebot',
}
