import { Relations } from '../features/ConditionalRendering/hooks/interfaces';

interface FindNameInRelation {
	name: string;
	relations: Relations[];
}

export function findNameInRelation({ relations, name }: FindNameInRelation) {
	if (!relations) return;
	return relations.find((_relations) => Object.values(_relations)
		.find((_relation) => _relation.key === name)) as Relations;
}
