import { atom } from 'recoil';

export interface FormDataValue {
	[key: string]: string;
}

export interface FormData {
	[screen: number]: FormDataValue;
}

const FormAtomData = atom<FormData>({
	default: {},
	key: 'FormData',
});

export default FormAtomData;
