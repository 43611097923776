import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/input-radio-v1';
// TODO remove this import after refactoring to lit component
// import '@oev-dxp/oev-components/dist/input-radio-v1.css';
import { Event } from './Constants';
import { setLabelText } from '../../utils/setLabelText';
import { RadioProps } from './Interfaces';

function Radio({
	label,
	mandatory = {
		choice: 'notmandatory',
		errorMessage: '',
	},
	options: optionsJSON,
	onChange,
	...props
}: Readonly<RadioProps>) {
	const ref = useRef<HTMLSelectElement | null>(null);
	const isMandatory = mandatory?.choice === 'mandatory';
	const errorText = mandatory?.errorMessage ?? '';

	useEffect(() => {
		ref.current?.addEventListener(Event.click, (ev: CustomEvent) => onChange({
			value: ev.detail.value,
		}));
	}, []);

	return (
		<oev-input-radio-v1
			custom-change-event-name={Event.click}
			errortext={errorText}
			label={setLabelText(label, isMandatory)}
			label-direction="right"
			mandatory={isMandatory}
			options={JSON.stringify(optionsJSON)}
			ref={ref}
			{...props}
		/>
	);
}

export default Radio;
