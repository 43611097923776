import { selector } from 'recoil';
import FormAtomData from '../atom/FormData';
import ScreenProgress from '../atom/ScreenProgress';

const CurrentScreenDataSelector = selector({
	get: ({ get }) => {
		const data = get(FormAtomData);
		const currentScreen = get(ScreenProgress);
		// flatten the object to an array with only one object and key:values
		const allData = Object.keys(data).map((screen) => data[parseInt(screen, 10)])
			.reduce((prev, cur) => ({
				...prev,
				...cur,
			}), {});
		return {
			all: allData,
			current: data[currentScreen],
			currentScreen,
		};
	},
	key: 'CurrentScreenDataSelector',
});

export default CurrentScreenDataSelector;
