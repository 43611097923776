import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/tile-container-v1';
import '@oev-dxp/oev-components/dist/tile-element-v1';
import { useRecoilState } from 'recoil';
import { TileProps } from './Interfaces';
import { setLabelText } from '../../utils/setLabelText';
import EngagementListAtom from '../../stores/atom/EngagementList';
import { updateEngagementListManually } from '../../utils/updateEngagementListManually';

function Tile({
	default: defaultChoice,
	disabled,
	editable,
	iconsize,
	label,
	mandatory,
	multiselect,
	name,
	onChange,
	options,
	selected,
	tooltip,
}: Readonly<TileProps>) {
	const ref = useRef<HTMLSelectElement | null>(null);
	const customChangeEventName = `oev-tile-${name}`;
	const errorText = mandatory?.errorMessage ?? '';
	const isEditable = editable === true;
	const isDisabled = disabled === true;
	const isMandatory = mandatory.choice === 'mandatory';
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);

	useEffect(() => {
		ref.current?.addEventListener(customChangeEventName, (ev: CustomEvent) => {
			const values = Object.values(ev.detail.value);
			const commaSeparatedString = values.join(', ');
			onChange({ value: commaSeparatedString });
		});
	}, []);

	return (
		<oev-tile-container-v1
			custom-change-event-name={customChangeEventName}
			data-testId="testIdTileContainer"
			default={defaultChoice}
			errortext={errorText}
			id={name}
			label={setLabelText(label, isMandatory)}
			name={name}
			ref={ref}
			tooltip={tooltip}
			{...(isMandatory ? { mandatory: '' } : null)}
			{...(multiselect ? { multiselect: '' } : null)}
		>
			{options?.map((option, index) => (
				<oev-tile-element-v1
					data-testId="testIdTileElement"
					disabled={disabled}
					editable={editable}
					icon={option?.icon}
					iconsize={iconsize}
					key={`tile:${index + 1}`}
					label={option?.label}
					name={option?.value}
					selected={selected}
					slot={option?.icon ? 'icon' : 'noIcon'}
					{...(option?.icon ? { icontype: 'icon' } : null)}
					{...(isEditable ? { editable: '' } : null)}
					{...(isDisabled ? { disabled: '' } : null)}
					onClick={() => updateEngagementListManually(engagementList, name, setEngagementList)}
				/>
			))}
		</oev-tile-container-v1>
	);
}

export default Tile;
