import React from 'react';
import { SupportedComponentsTypes } from '../../../interfaces';
import { textContentMarkup } from '../../../utils';
import usePlaceholderResolver from '../../../hooks/usePlaceholderResolver';
import '@oev-dxp/oev-components/dist/label';
import { ColumnStyle, LabelStyle, ValueStyle } from './Item.style';
import './Label.css';
import { SupportedComponent } from '../../constants/SupportedComponents';

interface ItemProps {
	label?: string;
	options?: {
		label: string;
		value: string;
	}[];
	type: SupportedComponentsTypes;
	value: string;
}

function Label({ label, markup }: Readonly<{ label?: string; markup?: string; }>) {
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{
				markup ? (
					<strong
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={textContentMarkup(markup || 'Fehlendes Label')}
						style={LabelStyle}
					/>
				) : <strong style={LabelStyle}>{label}</strong>
			}
		</>
	);
}

function Value({ value }: Readonly<{ value: string; }>) {
	return value ? <p style={ValueStyle}>{value}</p> : null;
}

function renderItemDetails(label: string, value: string) {
	return (
		<div style={ColumnStyle}>
			<Label label={label} />
			<Value value={value} />
		</div>
	);
}

// ToDo: See if oev-agency can be used differently in future
function determineType({
	type, options, label, value,
}: ItemProps) {
	// Use the line below if HTML elements are present in the Label Value provided by the config
	// if (label?.includes('<')) return null;

	const placeholderResolver = usePlaceholderResolver();
	const resolvedLabel = placeholderResolver(label ?? null);

	switch (type) {
		case SupportedComponent.radio:
		case SupportedComponent.dropdown: {
			const correctLabelToValue = options?.find((option) => option.value === value);
			return renderItemDetails(resolvedLabel || 'Fehlendes Label', correctLabelToValue?.label ?? 'Fehlendes Label Dropdown / Radio');
		}
		case SupportedComponent.checkbox:
			return (
				<div style={ColumnStyle}>
					<Label markup={resolvedLabel} />
					<Value value={value} />
				</div>
			);
		case 'oev-agency': {
			type SelectedAgencySummary = { agencyAddress: string; agencyName: string; };
			const { agencyName, agencyAddress } = JSON.parse(value) as SelectedAgencySummary;
			return (
				<div style={ColumnStyle}>
					<Label label={resolvedLabel || 'Fehlendes Label'} />
					<Value key={agencyName} value={agencyName} />
					<Value key={agencyAddress} value={agencyAddress} />
				</div>
			);
		}
		default:
			return renderItemDetails(resolvedLabel || 'Fehlendes Label', value);
	}
}

function Item(props: ItemProps) {
	return determineType({ ...props });
}

export default Item;
