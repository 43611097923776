import React, { useEffect, useRef } from 'react';
import {
	useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState,
} from 'recoil';
import { FormApplicationResultSuccess, FormApplicationResultSuccessParsed, submitFormApplication } from 'apiClient/serviceLayer';
import { WalletServiceResponseData } from 'features/Wallet';
import Button from '../Button';
import FormAtomData, { FormData, FormDataValue } from '../../stores/atom/FormData';
import FormConfig from '../../stores/atom/FormConfig';
import FormMandant from '../../stores/atom/FormMandant';
import FormConfigMandant from '../../stores/atom/FormConfigMandant';
import FormProductOwner from '../../stores/atom/FormProductOwner';
import SubmitData from '../../stores/selector/SubmitDataSelector';
import ScreenProgress from '../../stores/atom/ScreenProgress';
import EngagementListAtom from '../../stores/atom/EngagementList';
import wasFormSubmitted from '../../stores/atom/wasFormSubmitted';
import isFormSubmitting from '../../stores/atom/isFormSubmitting';
import WalletServiceResponse from '../../features/Wallet/stores/atom/WalletServiceResponse';
import TrackingSelector from '../../stores/selector/TrackingSelector';
import ActiveScreenSelector from '../../stores/selector/ActiveScreenSelector';
import SelectedTariff from '../../features/TariffComparison/stores/atom/SelectedTariff';
import { TrackingEvent, Alignment } from '../constants';
import { getAlignment } from '../../utils/getAlignment';
import { scrollToElementWithOffset, validateForms } from '../../utils';
import dataLayerHandler from '../../utils/tracking/dataLayerHandler';
import getInvalidFieldsWithError from '../../utils/tracking/getInvalidFieldsWithError';
import { ButtonProps } from '../Button/Interfaces';

function SubmitButton({ alignment = Alignment.right, ...props }: Readonly<ButtonProps>) {
	const submitData: FormDataValue = useRecoilValue(SubmitData);
	const [currentScreen, setCurrentScreen] = useRecoilState(ScreenProgress);
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);
	const trackingData = useRecoilValue(TrackingSelector);
	const activeScreen = useRecoilValue(ActiveScreenSelector);
	const selectedTariff = useRecoilValue(SelectedTariff);
	const setWasSubmitted = useSetRecoilState(wasFormSubmitted);
	const setWalletServiceResponse = useSetRecoilState(WalletServiceResponse);
	const [isSubmitting, setIsSubmitting] = useRecoilState(isFormSubmitting);
	const isSubmittingRef = useRef(isSubmitting);

	useEffect(() => {
		isSubmittingRef.current = isSubmitting;
	}, [isSubmitting]);

	const navigateStep = () => {
		const nextScreen = currentScreen + 1;
		if (nextScreen < 0) return;
		setCurrentScreen(nextScreen);
		scrollToElementWithOffset('top-scroll-point', 20);
	};

	const checkSuccess = (submitResponseParsed: FormApplicationResultSuccessParsed) => {
		const { processSteps } = submitResponseParsed;
		const unsuccessfulStep = processSteps?.find((step) => !step.successful);
		const isSuccessful = !unsuccessfulStep;
		if (!isSuccessful) throw new Error(`Form processing failed at service: ${unsuccessfulStep?.service}. Reason: ${unsuccessfulStep?.text}`);
		return isSuccessful;
	};

	const submitHandler = useRecoilCallback(({ snapshot }) => async () => {
		if (isSubmittingRef.current) return;
		setIsSubmitting(true); // Ensure isSubmitting is set to true
		try {
			const { id } = await snapshot.getPromise(FormConfig);
			const configMandant = await snapshot.getPromise(FormConfigMandant);
			const formData = await snapshot.getPromise(FormAtomData);
			const mandant = await snapshot.getPromise(FormMandant);
			const productOwner = await snapshot.getPromise(FormProductOwner);
			const screen = await snapshot.getPromise(ScreenProgress);

			const validForms = validateForms(formData[screen]);

			if (!validForms) {
				setIsSubmitting(false);
				return;
			}
			if (validForms.length) {
				const invalidFields = getInvalidFieldsWithError(formData[currentScreen as keyof FormData]);
				dataLayerHandler({
					event: TrackingEvent.formularError,
					invalidFields,
					trackingData,
					...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
				});
				const validFormElement: string = validForms?.[0];
				if (document.getElementById(validFormElement)) {
					scrollToElementWithOffset(validFormElement);
				}
				setIsSubmitting(false);
				return;
			}

			// ToDo: separate business logic from request logic
			try {
				const submitResponse = await submitFormApplication({
					'config-mandant': configMandant,
					formID: id,
					mandant,
					produktnehmer: productOwner,
					values: submitData,
				});

				if ('errorMessage' in submitResponse) throw new Error(`Error message from server: ${submitResponse?.errorMessage}`);
				// Needs to change in Backend:
				// "result" response should be a JSON object and not a serialized JSON string
				const submitResponseParsed = JSON.parse(
					(submitResponse as FormApplicationResultSuccess).result,
				) as FormApplicationResultSuccessParsed;

				checkSuccess(submitResponseParsed);
				setWasSubmitted(true);
				dataLayerHandler({
					event: TrackingEvent.formularSubmit,
					trackingData,
					...(selectedTariff?.tariffName && { tariffName: selectedTariff?.tariffName }),
				});
				setEngagementList({ ...engagementList, previousStep: { id: activeScreen?.id } });

				const walletServiceResponse = submitResponseParsed?.processSteps?.find((step) => step.service === 'wallet-service');
				if (walletServiceResponse) {
					// This is stupid.. wallet service response text needs to be a double escaped
					// JSON string to maintain its structure after fist deserialization above
					const walletServiceResponseParsed = JSON.parse(
						walletServiceResponse.text,
					) as WalletServiceResponseData;
					setWalletServiceResponse(walletServiceResponseParsed);
				}

				navigateStep();
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
				// eslint-disable-next-line no-alert
				alert('Übertragungsfehler: Das Formular kann zurzeit nicht versendet werden');
			}
		} finally {
			setIsSubmitting(false); // Ensure isSubmitting is set to false
		}
	}, [submitData]);
	return (
		<div style={{ display: 'flex', justifyContent: getAlignment(alignment) }}>
			<Button {...props} onClick={submitHandler} />
		</div>
	);
}

export default SubmitButton;
