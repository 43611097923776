import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import FormAtomData, { FormData } from '../../stores/atom/FormData';
import ScreenContext from '../../stores/selector/ScreenContextSelector';

export default function useFormData(
	key: string,
	init?: {
		screen: number;
		value: string | number | boolean | undefined;
	},
	initName?: string,
) {
	const [formData, setFormData] = useRecoilState(FormAtomData);
	const { currentScreen } = useRecoilValue(ScreenContext);

	const update = useCallback(({ screen = currentScreen, value }: {
		screen: number;
		value: string | number | boolean | undefined;
	}) => setFormData(
		(data: FormData) => ({
			...data,
			[screen]: {
				...data[screen],
				[key]: (!!value && String(value)) || '',
			},
		}),
	), [setFormData]);

	useEffect(() => {
		if (init && initName && formData[init.screen] === undefined) {
			update(init);
		}
	}, []);

	return update;
}
