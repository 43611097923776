import React, { useEffect, useRef } from 'react';
import '@oev-dxp/oev-components/dist/dropdown-v1';
import { useRecoilState } from 'recoil';
import { Event } from './Constants';
import { setLabelText } from '../../utils/setLabelText';
import EngagementListAtom from '../../stores/atom/EngagementList';
import { DropDownProps } from './Interfaces';
import { updateEngagementListManually } from '../../utils/updateEngagementListManually';

function Dropdown({
	default: defaultChoice,
	label,
	mandatory,
	onChange,
	options: optionsJSON,
	placeholder,
	...props
}: Readonly<DropDownProps>) {
	const ref = useRef<HTMLSelectElement | null>(null);
	const [engagementList, setEngagementList] = useRecoilState(EngagementListAtom);
	const isMandatory = mandatory.choice === 'mandatory';
	const errorText = mandatory?.errorMessage ?? '';
	const { name } = props;

	useEffect(() => {
		ref.current?.addEventListener(Event.click, (ev: CustomEvent) => onChange({
			value: ev.detail.value,
		}));
		// if no field is selected, all fields are [key]:false
		// we probably need to rewrite the dropdown because customer wants a new
		// design and behavior
	}, []);
	return (
		<oev-dropdown-v1
			custom-change-event-name={Event.click}
			data-testID="testIdDropdown"
			errortext={errorText}
			label={setLabelText(label, isMandatory)}
			onClick={() => updateEngagementListManually(engagementList, name, setEngagementList)}
			ref={ref}
			selectoptions={JSON.stringify(optionsJSON)}
			variant="single-select"
			{...props}
			{...defaultChoice ? { default: defaultChoice } : null}
			{...(isMandatory ? { mandatory: '' } : null)}
			{...placeholder ? { placeholder } : null}
		/>
	);
}
export default Dropdown;
