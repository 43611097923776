import React from 'react';
import '@oev-dxp/oev-components/dist/grid-v1';
// TODO remove this import after refactoring to lit component
// import '@oev-dxp/oev-components/dist/grid-v1.css';
import { useRecoilValue } from 'recoil';
import ComponentFactory from '../../factories/ComponentFactory';
import registerConditions from '../../features/ConditionalRendering/hooks/registerConditions';
import useConditionalRender from '../../features/ConditionalRendering/hooks/useConditionalRender';
import registerChildrenElementsInAppState from '../../features/ConditionalRendering/hooks/registerChildrenElementsInAppState';
import { getPresentComponent, Component, RowProps } from '../../interfaces';
import ScreenProgress from '../../stores/atom/ScreenProgress';

function Row({
	components, condition, uuid, headline, sectionId,
}: Readonly<RowProps>) {
	const currentScreen = useRecoilValue(ScreenProgress);
	registerConditions({ components, condition: condition || [], name: uuid });
	const show = useConditionalRender({ name: uuid });
	registerChildrenElementsInAppState({ currentScreen });
	if (!(show?.[uuid])) return null;
	return (
		<oev-row-v1 key={uuid}>
			{components.map(getPresentComponent).filter((value) => value !== undefined)
				.map((field: Component) => (
					<React.Fragment key={field?.data.name || field?.data.uuid}>
						<ComponentFactory
							key={field?.data.name}
							{...field}
							headline={headline}
							sectionId={sectionId}
						/>
					</React.Fragment>
				))}
		</oev-row-v1>
	);
}
export default Row;
