import React from 'react';
import { TariffEvent } from 'features/TariffComparison/components/Constants';
import Button from '../Button';
import { ButtonAction, Alignment } from '../constants';
import { getAlignment } from '../../utils/getAlignment';
import { ButtonProps } from '../Button/Interfaces';

const onClickHandler = () => {
	document.dispatchEvent(new CustomEvent(TariffEvent.calculate, {
		bubbles: true,
		composed: true,
	}));
};

function CalculationButton({ alignment = Alignment.right, ...rest }: Readonly<ButtonProps>) {
	return (
		<div style={{ display: 'flex', justifyContent: getAlignment(alignment) }}>
			<Button
				action={ButtonAction.calculation}
				color={rest.color}
				desktopWidth={rest.desktopWidth}
				eventLabel={rest.dataEventLabel || rest.labelText || rest.eventLabel}
				icon={rest.icon}
				iconJustification={rest.iconJustification}
				iconPosition={rest.iconPosition}
				labelText={rest.labelText}
				layout={rest.layout}
				onClick={onClickHandler}
				titleText={rest.titleText || rest.labelText}
				variant={rest.variant}
			/>
		</div>
	);
}

export default CalculationButton;
