import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import RenderElementsAtom from '../../../stores/atom/RenderElements';
import FormAtomData from '../../../stores/atom/FormData';
import Conditionals from '../stores/atom/Conditionals';
import CurrentScreenDataSelector from '../../../stores/selector/CurrentScreenDataSelector';
import { filterMissingElementsOfAppState } from '../../../utils/filterMissingElementsOfAppState';
import { getDefaultValuesForAppState } from '../../../utils/getDefaultValuesForAppState';
import { addMissingElementsToAppState } from '../../../utils/addMissingElementsToAppState';
import { FlattenMissingElements, UseMaintainAppState } from './interfaces';

/**
 *
 * Saves Elements initially in appsate.
 *
 * @param currentScreen Determines which screen is currently viewed
 *
 * On mount the hook iterate over all renderedElements and filters keys
 * which are not in the appState.
 * If a element is not initalized in AppState, we'll recieve the default props and
 * create a new object with all missing key:values.
 *
 * Lastlty there's a double check if the missing Item is not in the
 * elementsNotInState array. If so, it updates the AppState with
 * the missing items.
 */
function registerChildrenElementsInAppState({ currentScreen }: UseMaintainAppState) {
	const renderElement = useRecoilValue(RenderElementsAtom);
	const { all: allData } = useRecoilValue(CurrentScreenDataSelector);
	const [, setFormData] = useRecoilState(FormAtomData);
	const conditionals = useRecoilValue(Conditionals);

	useEffect(() => {
		// check if all elements are in AppState registered and is not a UUID
		const elementsNotInState: string[] = filterMissingElementsOfAppState(renderElement, allData);
		if (elementsNotInState.length === 0) return;

		const defaultValuesOfElementsArray = getDefaultValuesForAppState(
			elementsNotInState,
			conditionals,
		);

		const missingItemsInStateWithDefaults = defaultValuesOfElementsArray
			.reduce((prevVal, curVal) => ({
				...prevVal,
				...curVal,
			}), {} as FlattenMissingElements) as FlattenMissingElements;

		addMissingElementsToAppState({
			currentScreen,
			elementsNotInState,
			missingItemsInStateWithDefaults,
			setFormData,
		});
	}, [renderElement]);
}

export default registerChildrenElementsInAppState;
