import { useRecoilValue } from 'recoil';
import React from 'react';
import FilteredData from '../stores/atom/FilteredData';
import DetectRelationChangesSelector from '../stores/selector/DetectRelationChangesSelector';
import CurrentScreenDataSelector from '../stores/selector/CurrentScreenDataSelector';

function Debugging() {
	const filteredData = useRecoilValue(FilteredData);
	const relations = useRecoilValue(DetectRelationChangesSelector);
	const { all: appData } = useRecoilValue(CurrentScreenDataSelector);
	return (
		<>
			<h3>
				filtered
			</h3>
			{filteredData.map((key) => (
				<p key={key}>
					{key}
				</p>
			))}

			<h3> condition: </h3>
			<span>
				{relations?.map((conditions) => Object?.keys(conditions)?.map((condition) => (
					<div key={condition + conditions[condition].key}>
						key:
						{' '}
						{conditions[condition].key}
						{' '}
						<div>
							relation with:
							{' '}
							{conditions[condition].relationTo}
							{' '}
							condition:
							{' '}
							when
							{' '}
							{conditions[condition].when}
							{' '}
							than
							{' '}
							{conditions[condition].action || 'show'}
						</div>
						<div style={{ padding: '1rem' }}>
							current value:
							{conditions[condition].currentValue}
							{' '}
							action:
							{conditions[condition].action || 'show'}

						</div>
					</div>
				)))}
			</span>
			<h4>Alle Daten: </h4>
			{Object.keys(appData).map((key) => (
				<p key={key}>
					{[key]}
					{' '}
					:
					{' '}
					{appData[key]}
				</p>
			))}
		</>
	);
}

export default Debugging;
