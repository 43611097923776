import { FormType, TrackingEvent } from '../../components/constants';
import { addExclamationMarksToErrorFields } from './addExclamationMarksToErrorFields';
import { ScreenProps } from '../../interfaces/Screen';

export interface InvalidFields {
	errorMessage: string;
	field: string;
}

export interface TrackingData {
	currentScreen: number;
	engagedItems: string[];
	id: string;
	previousStep: {
		id: string;
	};
	screens: ScreenProps[];
}

export enum AgencySearchAction {
	delete = 'delete',
	pick = 'pick',
	search = 'search',
}

export interface AgencySearchData {
	action: AgencySearchAction;
	agency?: {
		name: string;
		number: string;
	};
	errorDescription?: string;
	keyword?: string;
	prevAgency?: {
		name: string;
		number: string;
	};
	results?: number;
}

export type EventType =
	TrackingEvent.formularInitialPageView
	| TrackingEvent.formularStep
	| TrackingEvent.formularError
	| TrackingEvent.formularSubmit
	| TrackingEvent.formularExit
	| TrackingEvent.formularSearch
	| TrackingEvent.profilerCalculate
	| TrackingEvent.profilerCalculateError;

export interface GetTrackObject {
	agencySearch?: AgencySearchData;
	event: EventType;
	invalidFields?: InvalidFields[];
	tariffName?: string;
	trackingData: TrackingData;
}
export default function getTrackObject({
	agencySearch, event, invalidFields, tariffName, trackingData,
}:GetTrackObject) {
	const {
		id, screens, currentScreen, previousStep, engagedItems,
	} = trackingData;

	const invalidField = invalidFields?.map((invalid) => invalid.field)
		.join(' ; ') ?? null;
	// should only be used for Event.formularError and Event.formularExit
	const engagedFieldsWithErrors = addExclamationMarksToErrorFields(engagedItems, invalidFields);

	const standardTrackingInfo = {
		formular_name: id,
		formular_step: screens[currentScreen]?.id,
		formular_typ: FormType.offer,
		originator: 'dxp',
	};

	const extendedTrackingInfo = {
		formular_engagement: engagedItems.join(' > ') || null,
		formular_field: engagedFieldsWithErrors[engagedFieldsWithErrors.length - 1] || null,
		...standardTrackingInfo,
	};

	// TODO edit default case
	switch (event) {
		case TrackingEvent.formularInitialPageView:
			return {
				event: TrackingEvent.formularStep, // needs to be step
				ga_four: {
					...standardTrackingInfo,
					...(tariffName && { tarif: tariffName }),
				},
			};
		case TrackingEvent.formularStep:
			return {
				event: TrackingEvent.formularStep,
				ga_four: {
					...extendedTrackingInfo,
					formular_prev: previousStep.id,
					...(tariffName && { tarif: tariffName }),
				},
			};
		case TrackingEvent.formularError:
			return {
				event: TrackingEvent.formularError,
				ga_four: {
					...extendedTrackingInfo,
					formular_fehler: invalidField,
					formular_prev: previousStep.id,
					...(tariffName && { tarif: tariffName }),
				},
			};
		case TrackingEvent.formularSubmit:
			return {
				event: TrackingEvent.formularSubmit,
				ga_four: {
					...extendedTrackingInfo,
					...(tariffName && { tarif: tariffName }),
				},
			};
		case TrackingEvent.formularExit:
			return {
				event: TrackingEvent.formularExit,
				ga_four: {
					...extendedTrackingInfo,
					formular_fehler: invalidField,
					page_location: document.location.hostname + document.location.pathname,
				},
			};
		case TrackingEvent.profilerCalculate:
			return {
				event: TrackingEvent.profilerCalculate,
				ga_four: {
					...standardTrackingInfo,
				},
			};
		case TrackingEvent.profilerCalculateError:
			return {
				event: TrackingEvent.profilerCalculateError,
				ga_four: {
					...standardTrackingInfo,
					formular_fehler: invalidField,
				},
			};
		case TrackingEvent.formularSearch:
			switch (agencySearch?.action) {
				case AgencySearchAction.search:
					return {
						event: TrackingEvent.formularSearch,
						ga_four: {
							...extendedTrackingInfo,
							formular_action: AgencySearchAction.search,
							formular_errordescription: agencySearch?.errorDescription,
							formular_keyword: agencySearch?.keyword,
							formular_method: 'location',
							formular_prev: previousStep.id,
							formular_results: agencySearch?.results,
							formular_widget: 'interaktive-beratersuche',
						},
					};
				case AgencySearchAction.pick:
					return {
						event: TrackingEvent.formularSearch,
						ga_four: {
							...extendedTrackingInfo,
							formular_action: AgencySearchAction.pick,
							formular_agency: agencySearch.agency,
							formular_prev: previousStep.id,
							formular_widget: 'interaktive-beratersuche',
						},
					};
				case AgencySearchAction.delete:
					return {
						event: TrackingEvent.formularSearch,
						ga_four: {
							...extendedTrackingInfo,
							formular_action: AgencySearchAction.delete,
							formular_prev: previousStep.id,
							formular_prevagency: agencySearch.prevAgency,
							formular_widget: 'interaktive-beratersuche',
						},
					};
				default: return {};
			}
		default: return {};
	}
}
