// 8px because 16.02.23 the headline comp has a default 8px padding bottom
import React from 'react';

export const HeadLineMobile = {
	margin: ' 0 0 16px 0',
};

export const HeadLineDesktop = {
	margin: '0 0 20px 0',
};

export const SubHeadLineMobile = {
	margin: '0 0 16px 0',
};

export const SubHeadLineDesktop = {
	margin: '0 0 16px 0',
};

export const GroupSeparatorStyle = {
	border: '1px solid var(--oev-color-gray-200)',
	margin: '-2px 0 var(--oev-spacing-m) 0',
	width: '100%',
} as React.CSSProperties;
export const SeparatorStyle = {
	border: '1px solid var(--oev-color-gray-200)',
	margin: '6px 0 var(--oev-spacing-l) 0',
	width: '100%',
} as React.CSSProperties;
