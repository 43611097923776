import React from 'react';
import { NavigationsSectionButton } from './NavigationsSectionButton';
import registerConditions from '../../features/ConditionalRendering/hooks/registerConditions';
import useConditionalRender from '../../features/ConditionalRendering/hooks/useConditionalRender';
import { FlexNavigation } from './Navigation.style';
import { NavigationProps } from './Interfaces';

export function Navigation({ flattenButtons, condition, uuid }: Readonly<NavigationProps>) {
	registerConditions({ condition: condition || [], name: uuid });
	const show = useConditionalRender({ name: uuid });
	if (!(show?.[uuid])) return null;
	if (!flattenButtons) return null;
	return (
		<div style={{ ...FlexNavigation }}>
			{flattenButtons.map((button, index) => {
				if (button === null) return null;
				return (
					<NavigationsSectionButton
						button={button}
						buttonsToRender={flattenButtons}
						currentPositionOfButton={index + 1}
						key={button?.data.uuid}
					/>
				);
			})}
		</div>
	);
}
