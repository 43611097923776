import { FormDataValue } from 'stores/atom/FormData';
import { SelectedAgency } from 'stores/atom/SelectedAgency';
import { SelectedTariffAtom } from 'features/TariffComparison/interfaces';
import { oevSessionStorageNamespace } from './Constants';

export interface OevSessionStorage {
	formData: FormDataValue;
	selectedAgency: SelectedAgency;
	selectedTariff: SelectedTariffAtom;
}

export default function getSessionStorage() {
	if (!window?.sessionStorage) return null;
	const oevUserSessionData = JSON.parse(window?.sessionStorage?.getItem(oevSessionStorageNamespace) ?? '{}') as OevSessionStorage;

	return oevUserSessionData;
}
