import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import Conditionals from '../stores/atom/Conditionals';
import {
	ConditionalMetaData,
	ConditionalChildren,
	ConditionalRendering,
	HandleChildrenOfElements,
	RegisterRelation,
} from './interfaces';
import { Components } from '../../../interfaces';

function iterateChildren(component: Components, relatedChildren: ConditionalChildren) {
	let tmpChildren: ConditionalChildren = {};
	Object.values(component).forEach((comp) => {
		if (!comp) return;
		const { data } = comp;
		tmpChildren = {
			...relatedChildren,
			...tmpChildren,
			[data.name || data.uuid]: (data.default === undefined || data.default === null) ? '' : String(data.default),
		};
	});
	return tmpChildren;
}

/**
 * Returns an Object of relatedChildren to a row / section
 *
 * @param rows only received from sections and rows
 * @param components can only be received from rows
 * Iterates through components and reduces all
 * children.
 * This is necessary for sections and rows. Fields are not affected
 */
function handleChildrenOfElements({
	rows,
	components,
}: HandleChildrenOfElements) {
	let relatedChildren: ConditionalChildren = {};

	switch (true) {
		case !!components:
			relatedChildren = components.reduce((acc, component) => iterateChildren(
				component,
				acc,
			), relatedChildren);
			break;

		case !!rows:
			relatedChildren = rows.reduce((acc, row) => {
				let updatedAcc = {
					...acc,
					[row.uuid]: '',
				};

				updatedAcc = row.components.reduce((compAcc, component) => (
					component ? iterateChildren(component, compAcc) : compAcc), updatedAcc);

				return updatedAcc;
			}, relatedChildren);
			break;

		default:
			return null;
	}

	return relatedChildren;
}

function mapCondition({
	condition, name, components, rows,
}: RegisterRelation) {
	const conditional: ConditionalMetaData = condition.reduce((prev, { data }) => ({
		children: handleChildrenOfElements({ components, rows }),
		condition: data.when.comparator,
		key: name,
		relationTo: data.when.referencedFieldName,
		then: data.then,
		when: data.when.value,
	}), {} as ConditionalMetaData);
	return conditional;
}

/**
 * Register Conditions of fields, rows and sections.
 *
 * @param condition from api. CAN exist inside of fields, rows and sections
 * @param name name / uuid of field, row or section
 * @param components can exist if the current object is a row
 * @param rows can exist if the current object is a section
 * @param hiddenFormFields contains all hidden fields
 *
 * Creates a Set of Conditions.
 * Each Condition has the following attributes:
 * realtionTo: displays the element which state should be monitored
 * key: name of the element
 * condition: equals or equalsNot
 * when: if the value of relationTo meets this value
 * then: show or hide element
 * children: A set of all children a section / row could have. If it hasn't any,
 * like a field it's null. Also all children are initialized with their default value
 */
function registerConditions({
	condition, name, components, rows, hiddenFormFields,
}: ConditionalRendering) {
	const [conditionals, setConditionals] = useRecoilState(Conditionals);
	useEffect(() => {
		if (hiddenFormFields?.length) {
			hiddenFormFields?.forEach((field) => {
				const hiddenFieldConditional = mapCondition({
					condition: field?.condition,
					name: field?.name,
				});
				if (field?.name) {
					setConditionals((prev) => ({
						...prev,
						[field.name]: {
							...prev[field.name],
							...hiddenFieldConditional,
						},
					}));
				}
			});
		}

		if (conditionals[name]) return;
		if (!condition[0]?.data?.when?.referencedFieldName) return;
		const conditional = mapCondition({
			components,
			condition,
			name,
			rows,
		});
		setConditionals((prev) => ({
			...prev,
			[name]: {
				...prev[name],
				...conditional,
			},
		}));
	}, []);
}

export default registerConditions;
