import { atom } from 'recoil';
import { HiddenFields } from '../../interfaces/HiddenFields';

const FormHiddenFields = atom<HiddenFields[]>({
	default: [{
		condition: [],
		defaultValue: '',
		name: '',
	}],
	key: 'FormHiddenFields',
});

export default FormHiddenFields;
