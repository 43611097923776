/* eslint-disable no-alert */
import { defaultRequestSettings, HTTPMethod } from 'apiClient/restLayer/Constants';
import { Service } from 'apiClient/serviceLayer/Constants';
import getFormGatewayUrl from 'apiClient/serviceLayer/formGatewayUrl';
import { b64toBlob } from '../util';
import { AbortControllerMap, NewFileMetaData } from '../Interfaces';

// TODO: separate business-logic from request-logic (/src/apiClient/serviceLayer/postUploadFile.ts)
const postFile = async (
	newFile: NewFileMetaData,
	identifier: {
		configMandant: string;
		formId: string;
		sessionId: string;
		uuid: string;
	},
	saveStorageId: Function,
	setAbortControllers: Function,
) => {
	const {
		configMandant,
		formId,
		sessionId,
		uuid,
	} = identifier;
	const fileUploadUrl = `${getFormGatewayUrl()}/${Service.formUpload}`;
	const fileBlob = await b64toBlob(newFile.base64DownloadURL);
	const formDataWebApi = new FormData();

	formDataWebApi.append('file', fileBlob, newFile.name);
	formDataWebApi.append('formId', formId);
	formDataWebApi.append('config-mandant ', configMandant);
	formDataWebApi.append('sessionId', sessionId);
	formDataWebApi.append('uuId', uuid);

	const requestSettings = { ...defaultRequestSettings };
	delete requestSettings.headers;

	// AbortControllers are added for each file that is being uploaded,
	// providing a way to interrupt and delete files that are still in the upload process
	const controller = new AbortController();
	setAbortControllers((prev: AbortControllerMap) => ({ ...prev, [newFile.name]: controller }));

	try {
		const response = await fetch(fileUploadUrl, {
			...requestSettings,
			body: formDataWebApi,
			method: HTTPMethod.post,
			signal: controller.signal,
		});
		saveStorageId(newFile, await response.json());
	} catch (error: any) {
		newFile.abort();
		if (error?.name === 'AbortError') return;
		// Temporary solution for error handling
		if (error?.errorMessage) {
			alert(`Bei der Datei ${newFile?.name} ist folgender Fehler aufgetreten: ${error?.errorMessage}`);
			return;
		}
		alert(`Beim Hochladen der Datei ${newFile?.name} ist ein Fehler aufgetreten`);
	}
};

export default postFile;
