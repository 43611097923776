import { useEffect, useState } from 'react';
import { fetchFormConfiguration } from 'apiClient/serviceLayer';
import { FormSequence } from '../../interfaces/FormSequence';

export default function useFormConfiguration(formId: string, configMandant: string) {
	const [config, setConfig] = useState<FormSequence>();
	const [error, setError] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		fetchFormConfiguration(formId, configMandant)
			.then(setConfig)
			.catch(setError)
			.finally(() => setIsLoading(false));
	}, [formId, configMandant]);

	return { config, error, isLoading };
}
