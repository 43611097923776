import { nextAction } from 'components/NavigationButton/NavigationButton';
import { ButtonAction } from 'components/constants/ButtonActions';
import { SetterOrUpdater } from 'recoil';

export const navigateStep = (currentScreen: number, setCurrentScreen: SetterOrUpdater<number>) => {
	const strideWidth = nextAction(ButtonAction.next);
	const nextScreen = strideWidth + currentScreen;
	if (nextScreen < 0) return;
	// ToDo: setEngagementList for tracking
	setCurrentScreen(nextScreen);
};
