import { atom } from 'recoil';
import { Option, SupportedComponentsTypes } from '../../interfaces';

export interface MetaData {
	headline?: string | null;
	isTariffRelevant?: boolean;
	label: string;
	options?: Option[];
	sectionId: string;
	type: SupportedComponentsTypes;
}
export interface FormDataMetaData {
	[fieldKey: string]: MetaData;
}

const FormDataMetaDataAtom = atom<FormDataMetaData>({
	default: {},
	key: 'FormDataMetaDataAtom',
});

export default FormDataMetaDataAtom;
