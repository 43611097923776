import DOMPurify from 'dompurify';

/**
 * Appends 'noopener' & 'noreferrer' to rel attr values to prevent reverse tabnabbing.
 *
 * @param {String} rel
 * @returns {String}
 */
const appendSecureRelValue = (rel: string) => {
	const attributes = new Set(rel ? rel.toLowerCase().split(' ') : []);

	attributes.add('noopener');
	attributes.add('noreferrer');

	return Array.from(attributes).join(' ');
};

const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';

DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
	if (node.tagName === 'A' && node.hasAttribute('target')) {
		node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target') ?? '');
	}
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
	if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
		node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE) || '_self');
		node.removeAttribute(TEMPORARY_ATTRIBUTE);
		if (node.getAttribute('target') === '_blank') {
			const rel = node.getAttribute('rel');
			node.setAttribute('rel', appendSecureRelValue(rel ?? ''));
		}
	}
});

export function textContentMarkup(markup: string) {
	return { __html: DOMPurify.sanitize(markup) };
}
